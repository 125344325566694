




























import { Component, Vue } from 'vue-property-decorator';
import EditTable from '@/components/Admin/EditTable.vue';
import { Product, ProductType, TableColumnDefinition } from '@/models/model';
import admin from '@/store/admin';
import home from '@/store/home';
import RentPricesHelper from '@/helper/rentPricesHelper';
import user from '@/store/user';

@Component({
  components: {
    EditTable
  },
})
export default class Products extends Vue {

    private columns: TableColumnDefinition[] = [
        { propertyName: 'label', label: 'Name' },
        { propertyName: 'labelEn', label: 'Name (en)' },
        { propertyName: 'description', label: 'Description' },
        { propertyName: 'type', label: 'Type', type: 'ProductType' },
        { propertyName: 'packages.length', label: 'Packages' },
        { propertyName: 'pictures.length', label: 'Pictures' },
        { propertyName: 'unavailabilityDates', label: 'Unavailable dates', type: 'DateRange' },
        { propertyName: 'color', label: 'Color', type: 'Color' }
    ];

    mounted(): void {
        if (!this.admin) {
          this.$router.push('/');
        }

        home.dispatchLoadDatas();
    }

    addProduct(): void {
        admin.editedProduct = ({label: '', labelEn: '', description: '', descriptionEn: '', type: ProductType.AdultSkis, pictures: [], rentPrices: RentPricesHelper.generateRentPrices(), packages: [], unavailabilityDates: [] } as any);
        this.$router.push('/Admin/Products/Edit');
    }

    get products(): Product[] {
        return home.products.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0);
    }

    get admin(): boolean {
      return !!user.currentUser && !!user.currentUser.roles && user.currentUser.roles.indexOf("Admin") !== -1;
    }
}
