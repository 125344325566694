


















































































import { Component, Vue } from 'vue-property-decorator';
import { GlobalPromotion } from '../../models/model';
import admin from '../../store/admin';
import CustomDatePicker from '@/components/Shared/CustomDatePicker.vue';
import toaster, { ToastImpl } from '@/store/toaster';

@Component({ components: { CustomDatePicker } })
export default class EditPromotion extends Vue {

    mounted(): void {
        if (!this.editedPromotion) {
            this.$router.push('/Admin/Promotions');
        }

        admin.editedPromotion = {...this.editedPromotion!};
    }

    cancel(): void {
        admin.editedPromotion = undefined;
        this.$router.push('/Admin/Promotions');
    }

    save(): void {
        admin.dispatchSavePromotion(this.editedPromotion!).then(() => {
            toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.saved')}`, `${this.$t('common.promotionSaved')}`));
            this.cancel();
        });
    }

    remove(): void {
        admin.dispatchDeletePromotion(this.editedPromotion!.id).then(() => {
            toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.removed')}`, `${this.$t('common.promotionRemoved')}`));
            this.cancel();
        });
    }

    get editedPromotion(): GlobalPromotion | undefined {
        return admin.editedPromotion;
    }

    get canSave(): boolean {
        return !!this.editedPromotion &&
            !!this.editedPromotion.label &&
            !!this.editedPromotion.labelEn &&
            !!this.editedPromotion.startDate &&
            !!this.editedPromotion.endDate &&
            this.editedPromotion.promotion > 0;
    }

    get isNew(): boolean {
        return !this.editedPromotion || !this.editedPromotion.id;
    }

}
