




































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CountrySelect from '@/components/Account/CountrySelect.vue';
import { User } from '@/models/model';
import user from '@/store/user';
import toaster, { ToastImpl } from '@/store/toaster';

@Component({
  components: {
    CountrySelect,
  },
})
export default class Address extends Vue {

    private editedUser: User = ({} as any);

    @Prop()
    user!: User;

    @Prop()
    readonly?: boolean;

    @Watch('user')
    userChanged(): void {
        this.init();
    }

    mounted(): void {
        this.init();
    }

    init(): void {
        this.editedUser = {...this.user!};
    }

    save(): void {
        user.dispatchSaveUser(this.editedUser).then(() => {
            toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.saved')}`, `${this.$t('common.addressSaved')}`));
            user.dispatchLoadCurrentUser();
        });
    }

    get canSave(): boolean {
        return !!this.editedUser.address && !!this.editedUser.country && !!this.editedUser.city && !!this.editedUser.postCode;
    }

}
