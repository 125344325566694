





















































































import { Accessory, Order, OrderProduct, Picture } from '@/models/model';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Api from '@/api/api';
import order from '@/store/order';
import home from '@/store/home';
import priceCalculator from '@/helper/priceCalculator';

@Component
export default class CartProducts extends Vue {

    @Prop()
    order!: Order;

    @Prop()
    readonly!: boolean;

    @Prop()
    panel?: boolean;

    @Prop()
    fullSize?: boolean;

    getAccessoryLabel(id: string): string {
        const accessory = this.accessories.find(a => a.id === id);
        return accessory ? accessory['label' + this.langSuffix] : 'Unknown';
    }

    getPictureUrl(picture: Picture): string {
        return Api.apiUrl + 'v1/document/picture/' + picture.hash + '?fileName=' + picture.name;
    }

    getPrice(orderProduct: OrderProduct, applyPromotion = true): number {
        return priceCalculator.getPrice(this.order, orderProduct, applyPromotion);
    }

    removeProduct(orderProduct: OrderProduct): void {
        order.commitSetRemoveProduct(orderProduct);
    }

    get accessories(): Accessory[] {
        return home.accessories;
    }

    get langSuffix(): string {
        return this.$root.$i18n.locale === 'en' ? 'En' : '';
    }
}
