import { RentPrice } from "@/models/model";

export default class RentPricesHelper {

    public static generateRentPrices(): RentPrice[] {
        const result = [];

        // Default, initiate 15 days
        for (let i = 0; i < 15; i++) {
            result.push({ days: i + 1, price: 0 });            
        }

        return result;
    }

}