
export default class InputValidator {

    public static checkInvalidEmail(email: string): boolean {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !!email && re.test(email.toLowerCase());
    }

    public static checkPassword(password: string): boolean {
        const re = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})');
        return !!password && re.test(password);
    }

    public static checkConfirmPassword(password: string, confirmPassword: string): boolean {
        return password === confirmPassword;
    }

}