



































































import { Component, Prop, Vue } from 'vue-property-decorator';
import user from '@/store/user';
import InputValidator from '@/helper/inputValidator';
import toaster, { ToastImpl } from '@/store/toaster';

@Component
export default class PasswordReset extends Vue {

    @Prop()
    userId?: string;


    private password = "";
    private confirmPassword = "";
    private invalidPassword = false;
    private invalidConfirmPassword = false;

    private showPassword = false;
    private showConfirmPassword = false;

    mounted(): void {
        if (!this.userId || !this.$route.query || !this.$route.query['token']) {
            this.$router.push('/');
            return;
        }
    }

    toggleShowPassword(property: string): void {
        (this as any)[property] = !(this as any)[property];
    }

    save(): void {
        this.invalidPassword = !InputValidator.checkPassword(this.password);
        this.invalidConfirmPassword = !InputValidator.checkConfirmPassword(this.password, this.confirmPassword);

        if (this.invalidPassword || this.invalidConfirmPassword) {
            return;
        }

        user.dispatchResetPassword({userId: this.userId, token: encodeURIComponent(this.$route.query['token'] as string), newPassword: this.password}).then(r => {
            if (r) {
                toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.saved')}`, `${this.$t('common.passwordSaved')}`));
                this.$router.push('/Login');
            } else {
                toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.error')}`, `${this.$t('common.errorMsg')}`));
            }
        });
    }

    get canSave(): boolean {
        return !!this.password && !!this.confirmPassword;
    }
}
