








import { Vue, Component } from 'vue-property-decorator';
import { Toast } from '../../models/model';
import ToastComponent from './Toast.vue';
import toaster from '../../store/toaster';

@Component({ components: { ToastComponent } })
export default class Toaster extends Vue {

    get toasts(): Toast[] {
        return toaster.toasts;
    }

}
