








import { Country } from '@/models/model';
import { Component, Prop, Vue } from 'vue-property-decorator';
import countries from '@/helper/countries';

@Component
export default class CountrySelect extends Vue {

    @Prop()
    value!: string;

    @Prop()
    readonly?: boolean;

    countryChanged(value: string): void {
        this.$emit('input', value);
    }

    get countryList(): Country[] {
        return countries.getCountries();
    }

    get langSuffix(): string {
        return this.$root.$i18n.locale === 'en' ? 'En' : '';
    }

}
