








import moment, { Moment } from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';


@Component
export default class CustomDatePicker extends Vue {

    private dateOptions: any = {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        icons: {
            time: 'bi-clock',
            date: 'bi-calendar',
            up: 'bi-chevron-up',
            down: 'bi-chevron-down',
            previous: 'bi-chevron-left',
            next: 'bi-chevron-right',
            today: 'bi-calendar-date',
            clear: 'bi-slash',
            close: 'bi-x'
        }
    };

    private dateValue: string | Moment = '';

    @Prop()
    value!: Date;

    @Watch('value')
    valueChanged(): void {
        if (this.value) {
            this.dateValue = moment(new Date(this.value.getFullYear(), this.value.getMonth(), this.value.getDate()));
        }
    }

    created(): void {
        this.dateOptions.locale = this.$root.$i18n.locale;
    }

    mounted(): void {
        if (this.value) {
            this.dateValue = moment(new Date(this.value.getFullYear(), this.value.getMonth(), this.value.getDate()));
        }
    }

    dateChanged(date: { date: Date }): void {
        if (date) {
            const d = new Date(date.date.valueOf());
            this.$emit('input', new Date(d.getFullYear(), d.getMonth(), d.getDate()));
        }
    }

}
