import { User } from "@/models/model";
import Api, { Error, getHeaders } from "./api";

export default class {

    static getToken(email: string, password: string): Promise<any & Error> {
        const urlencoded = new URLSearchParams();
        urlencoded.append("username", email);
        urlencoded.append("password", password);
        urlencoded.append("grant_type", "password");

        return fetch(Api.apiUrl + 'token', {
            method: 'POST',
            headers: getHeaders(false, 'application/json', 'application/x-www-form-urlencoded'),
            body: urlencoded,
            redirect: 'error'
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static getUser(): Promise<User & Error> {
        return fetch(Api.apiUrl + 'v1/user', {
            method: 'GET',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static signIn(email: string, password: string): Promise<User & Error> {
        return fetch(Api.apiUrl + 'v1/user', {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({email, password, confirmPassword: password})
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static saveUser(user: User): Promise<User & Error> {
        return fetch(Api.apiUrl + 'v1/user', {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(user)
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static changePassword(model: { oldPassword: string, newPassword: string, confirmPassword: string }): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/user/changePassword', {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(model)
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static confirmEmail(userId: string, token: string): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/user/confirm/' + userId + '?token=' + token, {
            method: 'GET',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static sendConfirmEmail(userId: string): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/user/sendConfirm/' + userId, {
            method: 'GET',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static askResetPassword(email: string): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/user/resetPassword?email=' + email, {
            method: 'GET',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static resetPassword(model: { userId: string, token: string, newPassword: string }): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/user/resetPassword', {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(model)
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }
}
