








































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '@/models/model';
import user from '@/store/user';

@Component
export default class Account extends Vue {

    @Prop()
    pageTitle!: string;

    mounted(): void {
        if (!this.currentUser) {
            this.$router.push('/');
        }
    }

    signout(): void {
      user.dispatchSignout().then(() => this.$router.push('/'));
    }

    get currentUser(): User | undefined {
        return user.currentUser;
    }

}
