






























































import { Component, Vue } from 'vue-property-decorator';
import Order, { CodeOrders } from '../../models/model';
import admin from '../../store/admin';
import OrderDetails from '@/components/Admin/OrderDetails.vue';

@Component({ components: { OrderDetails } })
export default class CodeOrdersDetails extends Vue {

    private selectedOrder: Order | undefined = ({} as any);

    mounted(): void {
        if (!this.editedCodeOrders) {
          this.$router.push('/Admin/CodesOrders');
        }
    }

    cancel(): void {
        admin.editedCodeOrders = undefined;
        this.selectedOrder = undefined;
        this.$router.push('/Admin/CodesOrders');
    }

    private showOrderDetails(order: Order): void {
      this.selectedOrder = order;
      var modal = (window as any).bootstrap.Modal.getOrCreateInstance(this.$refs.orderModal);
      modal.show();
    }

    get editedCodeOrders(): CodeOrders | undefined {
        return admin.editedCodeOrders;
    }

    get langSuffix(): string {
        return this.$root.$i18n.locale === 'en' ? 'En' : '';
    }
}
