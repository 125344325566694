
























































































import { Component, Vue } from 'vue-property-decorator';
import EditTable from '@/components/Admin/EditTable.vue';
import { Product } from '@/models/model';
import user from '@/store/user';
import home from '@/store/home';
import PriceCalculator from '@/helper/priceCalculator';

enum ViewMode {
  Pending = 1,
  Past = 2,
  Canceled = 3
}

@Component({
  components: {
    EditTable
  },
})
export default class Prices extends Vue {

    ViewMode = ViewMode;

    private productTypes = [1, 2, 3, 6, 4, 5];
    private selectedProductType = 1;
    private promotion = 20;

    mounted(): void {
        if (!this.manager) {
          this.$router.push('/');
        }

        home.dispatchLoadDatas();
    }

    getPriceWithPromotion(price: number): number {
      return PriceCalculator.round(price * (1 - (this.promotion / 100)));
    }

    get products(): Product[] {
      let products = home.products.filter(p => p.type == this.selectedProductType);

      return products.sort((a, b) => a.rentPrices[0].price > b.rentPrices[0].price ? -1 : a.rentPrices[0].price < b.rentPrices[0].price ? 1 : 0);
    }

    get manager(): boolean {
      return !!user.currentUser && !!user.currentUser.roles
        && (user.currentUser.roles.indexOf("Manager") !== -1 || user.currentUser.roles.indexOf("Admin") !== -1);
    }

    get langSuffix(): string {
      return this.$root.$i18n.locale === 'en' ? 'En' : '';
    }
}
