import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import VueMeta from 'vue-meta';
import Products from '../views/Admin/Products.vue';
import EditProduct from '../views/Admin/EditProduct.vue';
import ShoppingCart from '../views/Order/ShoppingCart.vue';
import Checkout from '../views/Order/Checkout.vue';
import AccountInformations from '../views/Account/Informations.vue';
import Security from '../views/Account/Security.vue';
import Orders from '../views/Account/Orders.vue';
import Accessories from '../views/Admin/Accessories.vue';
import EditAccessory from '../views/Admin/EditAccessory.vue';
import Promotions from '../views/Admin/Promotions.vue';
import EditPromotion from '../views/Admin/EditPromotion.vue';
import Codes from '../views/Admin/Codes.vue';
import EditCode from '../views/Admin/EditCode.vue';
import Banners from '../views/Admin/Banners.vue';
import EditBanner from '../views/Admin/EditBanner.vue';
import Seasons from '../views/Admin/Seasons.vue';
import EditSeason from '../views/Admin/EditSeason.vue';
import AdminOrders from '../views/Admin/Orders.vue';
import AdminPrices from '../views/Admin/Prices.vue';
import OrderDetails from '../views/Admin/OrderDetails.vue';
import Users from '../views/Admin/Users.vue';
import Dashboard from '../views/Admin/Dashboard.vue';
import Activation from '../views/Account/Activation.vue';
import PasswordReset from '../views/Account/PasswordReset.vue';
import EditUser from '../views/Admin/EditUser.vue';
import CodesOrders from '../views/Admin/CodesOrders.vue';
import CodeOrdersDetails from '../views/Admin/CodeOrdersDetails.vue';
import PaymentSuccess from '../views/Order/PaymentSuccess.vue';
import PaymentFail from '../views/Order/PaymentFail.vue';
import AwaitingOrders from '../views/Admin/AwaitingOrders.vue';
import Kpi from '../views/Admin/Kpi.vue';
import Error404 from '../views/404.vue';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { routes } = require('./routes.js');

Vue.use(VueRouter);
Vue.use(VueMeta);

export const otherRoutes: Array<RouteConfig> = [
  {
    path: '/Cart',
    name: 'Cart',
    component: ShoppingCart
  },
  {
    path: '/Cart/Checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/Order/PaymentSuccess',
    name: 'PaymentSuccess',
    component: PaymentSuccess
  },
  {
    path: '/Order/PaymentFail',
    name: 'PaymentFail',
    component: PaymentFail
  },
  {
    path: '/Account/Informations',
    name: 'AccountInformations',
    component: AccountInformations
  },
  {
    path: '/Account/Security',
    name: 'AccountSecurity',
    component: Security
  },
  {
    path: '/Account/Orders',
    name: 'AccountOrders',
    component: Orders
  },
  {
    path: '/Account/Activation/:userId',
    name: 'Activation',
    component: Activation,
    props: true
  },
  {
    path: '/Account/PasswordReset/:userId',
    name: 'PasswordReset',
    component: PasswordReset,
    props: true
  },
  {
    path: '/Admin/Products',
    name: 'Products',
    component: Products
  },
  {
    path: '/Admin/Products/Edit',
    name: 'EditProduct',
    component: EditProduct
  },
  {
    path: '/Admin/Accessories',
    name: 'Accessories',
    component: Accessories
  },
  {
    path: '/Admin/Accessories/Edit',
    name: 'EditAccessory',
    component: EditAccessory
  },
  {
    path: '/Admin/Promotions',
    name: 'Promotions',
    component: Promotions
  },
  {
    path: '/Admin/Promotions/Edit',
    name: 'EditPromotion',
    component: EditPromotion
  },
  {
    path: '/Admin/Codes',
    name: 'Codes',
    component: Codes
  },
  {
    path: '/Admin/Codes/Edit',
    name: 'EditCode',
    component: EditCode
  },
  {
    path: '/Admin/Banners',
    name: 'Banners',
    component: Banners
  },
  {
    path: '/Admin/Banners/Edit',
    name: 'EditBanner',
    component: EditBanner
  },
  {
    path: '/Admin/Seasons',
    name: 'Seasons',
    component: Seasons
  },
  {
    path: '/Admin/Seasons/Edit',
    name: 'EditSeason',
    component: EditSeason
  },
  {
    path: '/Admin/Orders',
    name: 'AdminOrders',
    component: AdminOrders
  },
  {
    path: '/Admin/Prices',
    name: 'AdminPrices',
    component: AdminPrices
  },
  {
    path: '/Admin/Orders/Details',
    name: 'OrderDetails',
    component: OrderDetails
  },
  {
    path: '/Admin/Users',
    name: 'Users',
    component: Users
  },
  {
    path: '/Admin/Users/Edit',
    name: 'EditUser',
    component: EditUser
  },
  {
    path: '/Admin/Dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/Admin/CodesOrders',
    name: 'CodesOrders',
    component: CodesOrders
  },
  {
    path: '/Admin/CodesOrders/Details',
    name: 'CodeOrdersDetails',
    component: CodeOrdersDetails
  },
  {
    path: '/Admin/AwaitingOrders',
    name: 'AwaitingOrders',
    component: AwaitingOrders
  },
  {
    path: '/Admin/Kpi',
    name: 'Kpi',
    component: Kpi
  },
  {
    path: '*',
    name: '404',
    component: Error404
  }
];

const router = new VueRouter({
  routes: routes.concat(otherRoutes),
  mode: 'history',
  scrollBehavior(): { x: number, y: number } {
    return { x: 0, y: 0 };
  }
})

export default router
