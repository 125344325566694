
























































































































import { Component, Vue } from 'vue-property-decorator';
import OrderSummary from '@/components/Cart/OrderSummary.vue';
import CountrySelect from '@/components/Account/CountrySelect.vue';
import order from '@/store/order';
import Order, { MoneticoParams, User } from '@/models/model';
import user from '@/store/user';
import PriceCalculator from '@/helper/priceCalculator';

@Component({
  components: {
    OrderSummary,
    CountrySelect
  },
})
export default class Checkout extends Vue {

    private user: User = ({} as any);
    private completedOrder: Order = ({} as any);
    private saving = false;

    mounted(): void {
        if (!this.currentOrder || !user.currentUser) {
            this.$router.push('/');
        }

        this.user = {...user.currentUser!};
    }

    placeOrder(): void {
        if (this.saving) {
            return;
        }

        this.saving = true;
        this.currentOrder!.basePrice = PriceCalculator.getTotalPriceBeforePromotions(this.currentOrder!);
        this.currentOrder!.price = PriceCalculator.getTotalPrice(this.currentOrder!);
        this.currentOrder!.deposit = PriceCalculator.getDeposit(this.currentOrder!);

        if (PriceCalculator.getGlobalPromotionsMoney(this.currentOrder!) === 0) {
            this.currentOrder!.globalPromotion = undefined;
            this.currentOrder!.globalPromotionId = '';
        }

        const lang = this.$root.$i18n.locale === 'en' ? 'EN' : 'FR';

        user.dispatchSaveUser(this.user).then(() => order.dispatchAddOrder({order: this.currentOrder!, lang: lang}).then((rr: MoneticoParams) => {
            this.saving = false;
            if (rr) {
                // Build form and submit
                const form = document.createElement('form');
                form.method = 'POST';
                form.action = rr.cgi;
                Object.keys(rr.form).forEach(key => {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = key;
                    input.value = rr.form[key];
                    form.appendChild(input);
                });
                document.body.appendChild(form);
                form.submit();
                //this.completedOrder = rr;
            }
        }));
    }

    get currentOrder(): Order | undefined {
        return order.currentOrder;
    }

    get canPlaceOrder(): boolean {
        return !!this.user.firstName && !!this.user.lastName && !!this.user.address && !!this.user.country &&
            !!this.user.phoneNumber && !!this.user.city && !!this.user.postCode;
    }

    get langSuffix(): string {
        return this.$root.$i18n.locale === 'en' ? 'En' : '';
    }
}
