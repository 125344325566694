








































































































import { Component, Vue } from 'vue-property-decorator';
import { PromotionCode, DateRange } from '../../models/model';
import admin from '../../store/admin';
import CustomDatePicker from '@/components/Shared/CustomDatePicker.vue';
import toaster, { ToastImpl } from '@/store/toaster';

@Component({ components: { CustomDatePicker } })
export default class EditCode extends Vue {

    mounted(): void {
        if (!this.editedCode) {
            this.$router.push('/Admin/Codes');
        }

        admin.editedCode = {...this.editedCode!};
    }

    addDate(): void {
      this.editedCode!.dates.push({startDate: '' as any, endDate: '' as any});
    }

    removeDate(date: DateRange): void {
      this.editedCode!.dates = this.editedCode!.dates.filter(d => d != date);
    }

    cancel(): void {
        admin.editedCode = undefined;
        this.$router.push('/Admin/Codes');
    }

    save(): void {
        admin.dispatchSaveCode(this.editedCode!).then(() => {
          toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.saved')}`, `${this.$t('common.codeSaved')}`));
          this.cancel();
        });
    }

    remove(): void {
        admin.dispatchDeleteCode(this.editedCode!.id).then(() => {
          toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.removed')}`, `${this.$t('common.codeRemoved')}`));
          this.cancel();
        });
    }

    get editedCode(): PromotionCode | undefined {
        return admin.editedCode;
    }

    get canSave(): boolean {
        return !!this.editedCode &&
            !!this.editedCode.code &&
            !!this.editedCode.label &&
            !!this.editedCode.labelEn &&
            this.editedCode.dates.length > 0 &&
            this.editedCode.dates.filter(d => !!d.startDate && !!d.endDate).length === this.editedCode.dates.length &&
            this.editedCode.promotion > 0;
    }

    get isNew(): boolean {
        return !this.editedCode || !this.editedCode.id;
    }

}
