








































import { Package, Product } from '@/models/model';
import { Component, Prop, Vue } from 'vue-property-decorator';
import home from '@/store/home';
import RentPricesHelper from '@/helper/rentPricesHelper';
import RentPrices from '@/components/Admin/RentPrices.vue';

@Component({ components: { RentPrices } })
export default class Packages extends Vue {

    @Prop()
    packages!: Package[];

    @Prop()
    productId!: string;

    mounted(): void {
        if (!this.products) {
            home.dispatchLoadDatas();
        }
    }

    addRow(): void {
        this.packages.push({ rentPrices: RentPricesHelper.generateRentPrices(), additionalProductIds: [], labelEn: '', label: '' });
    }

    deleteRow(pricePackage: Package): void {
        const index = this.packages.findIndex(d => d === pricePackage);
        this.packages.splice(index, 1);
    }

    get products(): Product[] {
        return home.products.filter(p => p.id !== this.productId);
    }

    get langSuffix(): string {
        return this.$root.$i18n.locale === 'en' ? 'En' : '';
    }
}
