









import { Component, Prop, Vue } from 'vue-property-decorator';
import user from '@/store/user';

@Component
export default class Activation extends Vue {

    private success = false;
    private loading = true;

    @Prop()
    userId?: string;

    mounted(): void {
        if (!this.userId || !this.$route.query || !this.$route.query['token']) {
            this.$router.push('/');
            return;
        }

        user.dispatchConfirmEmail({userId: this.userId, token: encodeURIComponent(this.$route.query['token'] as string)}).then(r => {
            this.loading = false;
            if (r) {
                user.dispatchCheckAuth();
                this.success = true;
            }
        });
    }

}
