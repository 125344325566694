


























import { DateRange, TableColumnDefinition } from '@/models/model';
import { Component, Prop, Vue } from 'vue-property-decorator';
import admin from '@/store/admin';

@Component
export default class EditTable extends Vue {

    @Prop()
    items!: any[];

    @Prop()
    columns!: TableColumnDefinition[];

    @Prop()
    editProperty!: string;

    @Prop()
    editRoute!: string;

    @Prop()
    greyedOnProperty?: string;

    edit(item: Record<string, unknown>, cancelClick: boolean): void {
        if (cancelClick) {
            return;
        }
        (admin as any)[this.editProperty] = item;
        this.$router.push(this.editRoute);
    }

    getValueHTML(item: Record<string, unknown>, propertyName: string, type: string): Record<string, unknown> {
        const propertyNames = propertyName.split('.');
        let obj = item;
        let i = 0;

        while (i < propertyNames.length) {
            obj = (obj as any)[propertyNames[i]];
            if (obj === undefined || obj == null) {
                return '' as any;
            }
            i++;
        }

        if (type === 'Money') {
            return (obj + ' €') as any;
        } else if (obj && type === 'ProductType') {
            return this.$t('productTypes.' + obj as any) as any;
        } else if (obj && type === 'Color') {
            return `<span class="d-block w-100 bg-${obj}">&nbsp;</span>` as any;
        } else if (obj && type === 'DateRange' && this.$options.filters) {
            return ((obj as any) as DateRange[])
                .map(d => this.$options.filters!.shortDate(d.startDate) + ' - ' + this.$options.filters!.shortDate(d.endDate))
                .join('<br>') as any;
        }

        if (obj instanceof Date && this.$options.filters) {
            return this.$options.filters.shortDate(obj);
        }

        if (Array.isArray(obj)) {
            return obj.join(', ') as any;
        }

        if (typeof(obj) === 'boolean') {
            return (obj ? this.$t('common.yes') : this.$t('common.no')) as any;
        }

        if ((obj as any) === '00000000-0000-0000-0000-000000000000') {
            return ('' as any);
        }

        return obj;
    }

}
