















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import order from '@/store/order';
import user from '@/store/user';
import Order, { Accessory, Picture, User } from '@/models/model';
import Api from '@/api/api';
import CartProducts from '@/components/Cart/CartProducts.vue';
import home from '@/store/home';
import PriceCalculator from '@/helper/priceCalculator';
import toaster, { ToastImpl } from '@/store/toaster';

@Component({ components: { CartProducts } })
export default class OrderCard extends Vue {

    @Prop()
    order!: Order;

    @Prop()
    readonly?: boolean;

    @Prop()
    detailled?: boolean;

    @Prop()
    cancellable?: boolean;

    getAccessoryLabel(id: string): void {
        const accessory = this.accessories.find(a => a.id === id);
        return accessory ? accessory['label' + this.langSuffix] : 'Unknown';
    }

    cancelOrder(confirm = false): void {
        if (!confirm) {
            var modal = (window as any).bootstrap.Modal.getOrCreateInstance(this.$refs.cancelModal);
            modal.show();
            return;
        }

        order.dispatchCancelOrder(this.order.id).then((r: boolean) => {
            if (r) {
                toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.canceled')}`, `${this.$t('common.orderCanceled')}`));
                order.dispatchLoadOrders();
                this.$router.go(-1);
            }
        });
    }

    viewOrder(): void {
        var modal = (window as any).bootstrap.Modal.getOrCreateInstance(this.$refs.orderDetailsModal);
        modal.show();
    }

    getPictureUrl(picture: Picture): string {
        return Api.apiUrl + 'v1/document/picture/' + picture.hash + '?fileName=' + picture.name;
    }

    getOrderStatus(): string {
        if (this.order.endedOn) {
            return 'view.account.canceled';
        }

        const rentDates = this.order.orderProducts.map(p => p.endDate);
        const maxDate = rentDates.reduce((a, b) => a > b ? a : b);
        const d = new Date();
        const currentDate = new Date(d.getFullYear(), d.getMonth(), d.getDate());

        if (maxDate < currentDate) {
            return 'view.account.past';
        }

        return 'view.account.booked';
    }

    get promotions(): number {
        return PriceCalculator.round(this.order.price - this.order.basePrice);
    }

    get promotionCodeMoney(): number {
        return PriceCalculator.getPromotionCodeMoney(this.order);
    }

    get langSuffix(): string {
        return this.$root.$i18n.locale === 'en' ? 'En' : '';
    }

    get user(): User | undefined {
        return user.currentUser;
    }

    get accessories(): Accessory[] {
        return home.accessories;
    }
}
