



































































import { Component, Vue } from 'vue-property-decorator';
import { Season } from '../../models/model';
import admin from '../../store/admin';
import CustomDatePicker from '@/components/Shared/CustomDatePicker.vue';
import toaster, { ToastImpl } from '@/store/toaster';

@Component({ components: { CustomDatePicker } })
export default class EditSeason extends Vue {

    mounted(): void {
        if (!this.editedSeason) {
            this.$router.push('/Admin/Seasons');
        }

        admin.editedSeason = {...this.editedSeason!};
    }

    cancel(): void {
        admin.editedSeason = undefined;
        this.$router.push('/Admin/Seasons');
    }

    save(): void {
        admin.dispatchSaveSeason(this.editedSeason!).then(() => {
          toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.saved')}`, `${this.$t('common.seasonSaved')}`));
          this.cancel();
        });
    }

    remove(): void {
        admin.dispatchDeleteSeason(this.editedSeason!.id).then(() => {
          toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.removed')}`, `${this.$t('common.seasonRemoved')}`));
          this.cancel();
        });
    }

    get editedSeason(): Season | undefined {
        return admin.editedSeason;
    }

    get canSave(): boolean {
        return !!this.editedSeason &&
            !!this.editedSeason.name &&
            !!this.editedSeason.startDate &&
            !!this.editedSeason.endDate;
    }

    get isNew(): boolean {
        return !this.editedSeason || !this.editedSeason.id;
    }

}
