import { render, staticRenderFns } from "./AwaitingOrders.vue?vue&type=template&id=51ef65ff&scoped=true&"
import script from "./AwaitingOrders.vue?vue&type=script&lang=ts&"
export * from "./AwaitingOrders.vue?vue&type=script&lang=ts&"
import style0 from "./AwaitingOrders.vue?vue&type=style&index=0&id=51ef65ff&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51ef65ff",
  null
  
)

export default component.exports