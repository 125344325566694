









import { Component, Vue } from 'vue-property-decorator';
import Account from '@/components/Account/Account.vue'
import BasicInfo from '@/components/Account/BasicInfo.vue';
import Address from '@/components/Account/Address.vue';
import { User } from '@/models/model';
import user from '@/store/user';

@Component({
  components: {
    Account,
    BasicInfo,
    Address
  },
})
export default class Informations extends Vue {

    mounted(): void {
        if (!this.currentUser) {
            this.$router.push('/');
        }
    }

    get currentUser(): User | undefined {
        return user.currentUser;
    }

}
