






















import { Component, Vue } from 'vue-property-decorator';
import EditTable from '@/components/Admin/EditTable.vue';
import { TableColumnDefinition, User } from '@/models/model';
import admin from '@/store/admin';
import user from '@/store/user';

@Component({
  components: {
    EditTable
  },
})
export default class Products extends Vue {

    private columns: TableColumnDefinition[] = [
        { propertyName: 'email', label: 'Email' },
        { propertyName: 'phoneNumber', label: 'Phone Number' },
        { propertyName: 'gender', label: 'Gender' },
        { propertyName: 'firstName', label: 'First Name' },
        { propertyName: 'lastName', label: 'Last Name' },
        { propertyName: 'roles', label: 'Roles' },
        { propertyName: 'emailConfirmed', label: 'Email Confirmed' }
    ];

    mounted(): void {
        if (!this.admin) {
          this.$router.push('/');
        }

        admin.dispatchLoadUsers();
    }

    get users(): User[] {
        return admin.users.sort((a, b) => a.email > b.email ? 1 : a.email < b.email ? -1 : 0);
    }

    get admin(): boolean {
      return !!user.currentUser && !!user.currentUser.roles && user.currentUser.roles.indexOf("Admin") !== -1;
    }
}
