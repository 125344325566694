




















































































import { Component, Vue } from 'vue-property-decorator';
import { Banner } from '../../models/model';
import admin from '../../store/admin';
import CustomDatePicker from '@/components/Shared/CustomDatePicker.vue';
import toaster, { ToastImpl } from '@/store/toaster';

@Component({ components: { CustomDatePicker } })
export default class EditBanner extends Vue {

    mounted(): void {
        if (!this.editedBanner) {
            this.$router.push('/Admin/Banners');
        }

        admin.editedBanner = {...this.editedBanner!};
    }

    cancel(): void {
        admin.editedBanner = undefined;
        this.$router.push('/Admin/Banners');
    }

    save(): void {
        admin.dispatchSaveBanner(this.editedBanner!).then(() => {
          toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.saved')}`, `${this.$t('common.bannerSaved')}`));
          this.cancel();
        });
    }

    remove(): void {
        admin.dispatchDeleteBanner(this.editedBanner!.id).then(() => {
          toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.removed')}`, `${this.$t('common.bannerRemoved')}`));
          this.cancel();
        });
    }

    get editedBanner(): Banner | undefined {
        return admin.editedBanner;
    }

    get canSave(): boolean {
        return !!this.editedBanner &&
            !!this.editedBanner.label &&
            !!this.editedBanner.labelEn &&
            !!this.editedBanner.startDate &&
            !!this.editedBanner.endDate &&
            (!this.editedBanner.promotion || (this.editedBanner.promotion && this.editedBanner.promotionPct !== 0));
    }

    get isNew(): boolean {
        return !this.editedBanner || !this.editedBanner.id;
    }

}
