
























































import { Component, Vue } from 'vue-property-decorator';
import EditTable from '@/components/Admin/EditTable.vue';
import Order, { DepositState, Season, TableColumnDefinition } from '@/models/model';
import admin from '@/store/admin';
import user from '@/store/user';

enum ViewMode {
  InProgress = 1,
  Failed = 2
}

@Component({
  components: {
    EditTable
  },
})
export default class AwaitingOrders extends Vue {

    ViewMode = ViewMode;

    // Todo columns label = i18n key
    private columns: TableColumnDefinition[] = [
        { propertyName: 'number', label: 'view.account.orderNo' },
        { propertyName: 'moneticoReference', label: 'view.editOrder.depositRef' },
        { propertyName: 'user.email', label: 'view.account.email' },
        { propertyName: 'depositFlowStartDate', label: 'view.editOrder.depositDate' },
        { propertyName: 'price', label: 'view.account.total', type: 'Money' },
        { propertyName: 'deposit', label: 'common.deposit', type: 'Money' },
        { propertyName: 'createdOn', label: 'common.createdOn' }
    ];

    private viewMode = ViewMode.InProgress;
    private seasons: Season[] = [];
    private selectedSeason: Season = ({} as any);

    mounted(): void {
        if (!this.manager) {
          this.$router.push('/');
        }

        this.initSeasons();

        admin.dispatchLoadOrders().then(() => this.orders.forEach(o => (o as any).dates = Array.from(new Set(o.orderProducts.map(p => p.startDate.toLocaleDateString() + ' - ' + p.endDate.toLocaleDateString()))).join(', ')));
    }

    initSeasons(): void {
      const startYear = 2021;
      const date = new Date();
      const currentYear = date.getFullYear() - (date.getMonth() <= 6 ? 1 : 0);
      const years = [...Array(currentYear - startYear).keys()];
      for (let i = years.length; i >= 0; i--) {
        const currentSeason = startYear + i;
        this.seasons.push({
          id: i.toString(),
          name: currentSeason + '/' + (currentSeason + 1),
          startDate: new Date(currentSeason, 8, 1),
          endDate: new Date(currentSeason + 1, 7, 30),
          active: true
        });
      }

      this.selectedSeason = this.seasons[0];
    }

    get orders(): Order[] {
      let orders = admin.orders;

      if (this.selectedSeason && this.selectedSeason.name) {
        orders = orders.filter(o => o.orderProducts && o.orderProducts.length ?
          o.orderProducts[0].startDate >= this.selectedSeason.startDate && o.orderProducts[0].endDate <= this.selectedSeason.endDate :
          o.createdOn >= this.selectedSeason.startDate && o.createdOn <= this.selectedSeason.endDate);
      }

      return orders.sort((a, b) => a.createdOn > b.createdOn ? -1 : a.createdOn < b.createdOn ? 1 : 0);
    }

    get inProgressOrders(): Order[] {
      return this.orders.filter(o => o.depositState == DepositState.InProgress);
    }

    get failedOrders(): Order[] {
      return this.orders.filter(o => o.depositState == DepositState.Failed);
    }

    get manager(): boolean {
      return !!user.currentUser && !!user.currentUser.roles
        && (user.currentUser.roles.indexOf("Manager") !== -1 || user.currentUser.roles.indexOf("Admin") !== -1);
    }
}
