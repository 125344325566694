















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Toast } from '../../models/model';

@Component
export default class ToastComponent extends Vue {
    @Prop()
    toast!: Toast;
}
