




























import { Component, Vue } from 'vue-property-decorator';
import EditTable from '@/components/Admin/EditTable.vue';
import { Season, TableColumnDefinition } from '@/models/model';
import admin from '@/store/admin';
import user from '@/store/user';

@Component({
  components: {
    EditTable
  },
})
export default class Seasons extends Vue {

    private columns: TableColumnDefinition[] = [
        { propertyName: 'name', label: 'Name' },
        { propertyName: 'startDate', label: 'Start date' },
        { propertyName: 'endDate', label: 'End date' },
        { propertyName: 'active', label: 'Active' }
    ];

    mounted(): void {
      if (!this.admin) {
        this.$router.push('/');
      }

      admin.dispatchLoadSeasons();
    }

    addSeasons(): void {
        admin.editedSeason = ({name: '', startDate: '', endDate: '', active: false } as any);
        this.$router.push('/Admin/Seasons/Edit');
    }

    get seasons(): Season[] {
        return admin.seasons.sort((a, b) => a.name > b.name ? -1 : a.name < b.name ? 1 : 0);
    }

    get admin(): boolean {
      return !!user.currentUser && !!user.currentUser.roles && user.currentUser.roles.indexOf("Admin") !== -1;
    }
}
