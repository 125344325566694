




















import { Component, Vue } from 'vue-property-decorator';
import { Order } from '../../models/model';
import admin from '../../store/admin';
import OrderDetailsComp from '../../components/Admin/OrderDetails.vue';
import home from '@/store/home';

@Component({ components: { OrderDetailsComp } })
export default class OrderDetails extends Vue {

    mounted(): void {
        if (!this.editedOrder) {
            if (this.$route.query && this.$route.query.id) {
              admin.dispatchLoadEditedOrder(this.$route.query.id).then(() => {
                if (!this.editedOrder) {
                  this.$router.push('/Admin/Orders');
                }
                home.dispatchLoadDatas();
              });
            } else {
              this.$router.push('/Admin/Orders');
            }
        } else {
          home.dispatchLoadDatas();
        }
    }

    get editedOrder(): Order | undefined {
        return admin.editedOrder;
    }

    get langSuffix(): string {
        return this.$root.$i18n.locale === 'en' ? 'En' : '';
    }
}
