import Order, { GlobalPromotion, OrderProduct, PromotionCode } from "@/models/model";
import home from '@/store/home';

export default class PriceCalculator {

    public static getPrice(order: Order, orderProduct: OrderProduct, applyPromotion = true): number {
        const days = this.datediff(orderProduct.startDate, orderProduct.endDate);
        const promotion = this.getGlobalPromotion(order);

        let accessoriesPrice = 0;
        if (orderProduct.productAccessories && orderProduct.productAccessories.length) {
            const accessories = home.accessories;
            orderProduct.productAccessories.forEach(a => {
                const accessory = accessories.find(aa => aa.id === a.accessoryId);
                if (accessory) {
                    if (!(orderProduct.age && accessory.freeUnderAge && orderProduct.age < accessory.freeUnderAge)) {
                        accessoriesPrice += (accessory.rentPrices.find(p => p.days == days)?.price ?? 0) * a.quantity;
                    }
                }
            });
        }

        let promotionPct = 1;
        if (applyPromotion && promotion) {
            if (promotion.endDate.getHours() !== 23) {
                promotion.endDate.setHours(23, 59, 59);
            }
            if (promotion.minRentDays === 0 || this.datediff(orderProduct.startDate, orderProduct.endDate) >= promotion.minRentDays) {
                if (promotion.startDate <= orderProduct.startDate && promotion.endDate >= orderProduct.endDate) {
                    promotionPct = 1 - (promotion.promotion / 100);
                }
            }
        }

        if (orderProduct.package) {
            const packgeRentPrice = orderProduct.package.rentPrices.find(p => p.days == days);
            return this.round(accessoriesPrice + ((packgeRentPrice ? packgeRentPrice.price : 0) * promotionPct));
        }
        const rentPrice = orderProduct.product.rentPrices.find(p => p.days == days);
        return this.round(accessoriesPrice + ((rentPrice ? rentPrice.price : 0) * promotionPct));
    }

    public static getTotalPrice(order: Order): number {
        return this.round(this.getTotalPriceBeforePromotions(order) + this.getGlobalPromotionsMoney(order) + this.getPromotionCodeMoney(order));
    }

    public static getTotalPriceBeforePromotions(order: Order): number {
        return this.round(order.orderProducts.reduce((a, b) => a + this.getPrice(order, b, false), 0));
    }

    public static getTotalPriceWithGlobalPromotions(order: Order): number {
        return this.round(order.orderProducts.reduce((a, b) => a + this.getPrice(order, b), 0));
    }

    public static getGlobalPromotion(order: Order): GlobalPromotion | undefined {
        return order.globalPromotionId ? order.globalPromotion : undefined;
    }

    public static getGlobalPromotionsMoney(order: Order): number {
        return this.round(this.getTotalPriceWithGlobalPromotions(order) - this.getTotalPriceBeforePromotions(order));
    }

    public static getPromotionCodeMoney(order: Order): number {
        let money = 0;

        if (order && order.orderProducts && order.orderProducts.length) {
            const code = order.promotionCode;

            if (code && code.id) {
                order.orderProducts.forEach(p => {
                    if (this.checkCodeApplicableProduct(p, code)) {
                        const productPrice = this.getPrice(order, p);
                        const productPriceWithCode = productPrice * (1 - (code.promotion / 100));
                        money += productPriceWithCode - productPrice;
                    }
                });
            }
        }

        return this.round(money);
    }

    public static checkCodeApplicable(order: Order, code: PromotionCode): boolean {
        let applicable = false;
        order.orderProducts.forEach(p => {
            if (this.checkCodeApplicableProduct(p, code)) {
                applicable = true;
            }
        });
        return applicable;
    }

    public static checkCodeApplicableProduct(product: OrderProduct, code: PromotionCode): boolean {
        code.dates.forEach(d => {
            if (d.endDate.getHours() !== 23) {
                d.endDate.setHours(23, 59, 59);
            }
        });
        // No code before 3 days of rent
        const days = this.datediff(product.startDate, product.endDate);
        if (days < 3) {
            return false;
        }
        if (code.minRentDays > 0 && days < code.minRentDays) {
            return false;
        }
        return code.dates.filter(d => d.startDate <= product.startDate && d.endDate >= product.endDate).length > 0;
    }

    public static round(value: number): number {
        return Math.round(value * 10) / 10;
    }

    public static datediff(first: Date, second: Date): number {
        second.setHours(23, 59, 59);
        const days = Math.round((second.getTime() - first.getTime())/(1000*60*60*24));
        return days;
    }

    public static getDeposit(order: Order): number {
        return this.round(this.getTotalPrice(order) * 0.2);
    }
}