import { User } from "../models/model";
import { UserState, RootState } from "../models/state";
import { BareActionContext, getStoreBuilder } from "vuex-typex";
import UserApi from "@/api/userApi";
import toaster, { ToastImpl } from "./toaster";

const initialUserState: UserState = {
    currentUser: undefined,
    redirectRoute: undefined,
    loggedIn: false,
    loading: false
};
const b = getStoreBuilder<RootState>().module("user", initialUserState);

const loadingGetter = b.read((state: UserState) => state.loading, "loading");
const currentUserGetter = b.read((state: UserState) => state.currentUser, "currentUser");
const redirectRouteGetter = b.read((state: UserState) => state.redirectRoute, "redirectRoute");
const loggedInGetter = b.read((state: UserState) => state.loggedIn, "loggedIn");

function setLoading(state: UserState, payload: boolean): void
{
    state.loading = payload;
}

function setCurrentUser(state: UserState, payload: User | undefined): void
{
    state.currentUser = payload;
}

function setRedirectRoute(state: UserState, payload: string | undefined): void
{
    state.redirectRoute = payload;
}

function setLoggedIn(state: UserState, payload: boolean): void
{
    state.loggedIn = payload;
}

async function loadCurrentUser(): Promise<boolean> {
    const loadedUser = await UserApi.getUser();

    if ((loadedUser as any).error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', (loadedUser as any).error));
        console.log('Error: ' + (loadedUser as any).error);
        return false;
    }

    user.currentUser = loadedUser;

    return true;
}

async function login(context: BareActionContext<UserState, RootState>, params: {email: string, password: string}): Promise<boolean> {
    if (process.env.VUE_APP_SERV_ENV && params.email != 'florianconstant@gmail.com') {
        return false;
    }
    const token = await UserApi.getToken(params.email, params.password);

    if (token && token.access_token) {
        localStorage.setItem("token", token.access_token);
        await user.dispatchCheckAuth();
        return true;
    }

    return false;
}

async function checkAuth(): Promise<boolean> {
    if (localStorage.getItem('token')) {
        // Check token validity
        const test = await UserApi.getUser();
        if (!test || Object.keys(test).indexOf('error') !== -1) {
            localStorage.removeItem('token');
            return false;
        }

        return user.dispatchLoadCurrentUser().then(() => {
            user.loggedIn = true;

            return true;
        });
    }

    return false;
}

async function signIn(context: BareActionContext<UserState, RootState>, params: {email: string, password: string}): Promise<boolean> {
    const createdUser = await UserApi.signIn(params.email, params.password);

    if ((createdUser as any).error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', (createdUser as any).error));
        console.log('Error: ' + (createdUser as any).error);
        return false;
    }

    return true;
}

async function signout(): Promise<boolean> {
    localStorage.removeItem('token');
    user.loggedIn = false;
    user.currentUser = undefined;

    return true;
}

async function saveUser(context: BareActionContext<UserState, RootState>, updatedUser: User): Promise<boolean> {
    const savedUser = await UserApi.saveUser(updatedUser);

    if ((savedUser as any).error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', (savedUser as any).error));
        console.log('Error: ' + (savedUser as any).error);
        return false;
    }

    user.currentUser = savedUser;

    return true;
}

async function changePassword(context: BareActionContext<UserState, RootState>, model: { oldPassword: string, newPassword: string, confirmPassword: string }): Promise<boolean> {
    const changeStatus = await UserApi.changePassword(model);

    if ((changeStatus as any).error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', (changeStatus as any).error));
        console.log('Error: ' + (changeStatus as any).error);
        return false;
    }

    return changeStatus;
}

async function confirmEmail(context: BareActionContext<UserState, RootState>, model: { userId: string, token: string }): Promise<boolean> {
    const confirmStatus = await UserApi.confirmEmail(model.userId, model.token);

    if ((confirmStatus as any).error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', (confirmStatus as any).error));
        console.log('Error: ' + (confirmStatus as any).error);
        return false;
    }

    return confirmStatus;
}

async function sendConfirmationEmail(context: BareActionContext<UserState, RootState>, userId: string): Promise<boolean> {
    const confirmEmail = await UserApi.sendConfirmEmail(userId);

    if ((confirmEmail as any).error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', (confirmEmail as any).error));
        console.log('Error: ' + (confirmEmail as any).error);
        return false;
    }

    return confirmEmail;
}

async function sendResetPassword(context: BareActionContext<UserState, RootState>, email: string): Promise<boolean> {
    const resetEmail = await UserApi.askResetPassword(email);

    if ((resetEmail as any).error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', (resetEmail as any).error));
        console.log('Error: ' + (resetEmail as any).error);
        return false;
    }

    return resetEmail;
}

async function resetPassword(context: BareActionContext<UserState, RootState>, model: { userId: string, token: string, newPassword: string }): Promise<boolean> {
    const resetStatus = await UserApi.resetPassword(model);

    if ((resetStatus as any).error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', (resetStatus as any).error));
        console.log('Error: ' + (resetStatus as any).error);
        return false;
    }

    return resetStatus;
}

const stateGetter = b.state()

const user = {
    get state(): UserState { return stateGetter() },

    get loading(): boolean { return loadingGetter() },
    set loading(value: boolean) { this.commitSetLoading(value) },

    get currentUser(): User | undefined { return currentUserGetter() },
    set currentUser(value: User | undefined) { this.commitSetCurrentUser(value); },

    get redirectRoute(): string | undefined { return redirectRouteGetter() },
    set redirectRoute(value: string | undefined) { this.commitSetRedirectRoute(value); },

    get loggedIn(): boolean { return loggedInGetter() },
    set loggedIn(value: boolean) { this.commitSetLoggedIn(value); },

    commitSetLoading: b.commit(setLoading),
    commitSetCurrentUser: b.commit(setCurrentUser),
    commitSetRedirectRoute: b.commit(setRedirectRoute),
    commitSetLoggedIn: b.commit(setLoggedIn),

    dispatchLogin: (b.dispatch(login) as (param?: any) => Promise<boolean>),
    dispatchLoadCurrentUser: (b.dispatch(loadCurrentUser) as (param?: any) => Promise<boolean>),
    dispatchCheckAuth: (b.dispatch(checkAuth) as (param?: any) => Promise<boolean>),
    dispatchSignout: (b.dispatch(signout) as (param?: any) => Promise<boolean>),
    dispatchSaveUser: (b.dispatch(saveUser) as (param?: any) => Promise<boolean>),
    dispatchSignIn: (b.dispatch(signIn) as (param?: any) => Promise<boolean>),
    dispatchChangePassword: (b.dispatch(changePassword) as (param?: any) => Promise<boolean>),
    dispatchConfirmEmail: (b.dispatch(confirmEmail) as (params?: any) => Promise<boolean>),
    dispatchSendConfirmationEmail: (b.dispatch(sendConfirmationEmail) as (param?: any) => Promise<boolean>),
    dispatchSendResetPassword: (b.dispatch(sendResetPassword) as (param?: any) => Promise<boolean>),
    dispatchResetPassword: (b.dispatch(resetPassword) as (param?: any) => Promise<boolean>)
}

export default user