import Order, { MoneticoParams } from "@/models/model";

export interface Error {
    error?: string;
}

export default class {

    public static apiUrl = process.env.VUE_APP_API_URL;

    static getDatas(): Promise<any & Error> {
        return fetch(this.apiUrl + 'v1/home/data', {
            method: 'GET',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static getOrders(): Promise<any & Error> {
        return fetch(this.apiUrl + 'v1/order', {
            method: 'GET',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static addOrder(order: Order, lang: string): Promise<MoneticoParams & Error> {
        return fetch(this.apiUrl + `v1/order?lang=${lang}`, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(order)
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static cancelOrder(orderId: string): Promise<any & Error> {
        return fetch(this.apiUrl + 'v1/order/' + orderId, {
            method: 'DELETE',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static checkCode(code: string): Promise<any & Error> {
        return fetch(this.apiUrl + 'v1/order/check-code/' + code, {
            method: 'GET',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static uploadDocument(document: File): Promise<string & Error> {
        return fetch(this.apiUrl + 'v1/document', {
            method: 'POST',
            headers: getHeaders(true, 'application/json', 'text/json'),
            body: document
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static downloadDocument(hash: string, fileName: string): Promise<Blob & Error> {
        return fetch(this.apiUrl + 'v1/document/' + hash + '?fileName=' + fileName, {
            method: 'GET',
            headers: getHeaders(true, 'application/json', 'text/json')
        })
        .catch(r => {
            return { error: r.message };
        })
        .then((r: any) => {
            if (r.ok) {
                return r.blob();
            }
            return { error: r.statusText };
        });
    }

}

export const convertJsonDates = function(o: Record<string, any>, dates: string[]): void {
    Object.keys(o).forEach(k => {
        if (dates.indexOf(k) !== -1 && o[k] && o[k].length) {
            let noTimeDate = o[k];

            // Set hours to 0
            const tIndex = noTimeDate.indexOf('T');
            if (tIndex !== -1) {
                noTimeDate = noTimeDate.substr(0, tIndex) + 'T00:00:00';
            }

            o[k] = new Date(noTimeDate);
        } else if (Array.isArray(o[k]) || (o[k] instanceof Object && o[k].constructor === Object)) {
            convertJsonDates(o[k], dates);
        }
    });
}

export const getHeaders = function(useAuth = true, accept = 'application/json', contentType = 'application/json'): Headers {
    const headers: any = { 'Accept': accept, 'Content-Type': contentType };

    if (useAuth) {
        const token = localStorage.getItem('token');
        headers['Authorization'] = 'Bearer ' + token;
    }

    return new Headers(headers);
}