






































import { Component, Vue } from 'vue-property-decorator';
import EditTable from '@/components/Admin/EditTable.vue';
import Order, { Season, TableColumnDefinition, CodeOrders, DepositState } from '@/models/model';
import admin from '@/store/admin';
import user from '@/store/user';
import home from '@/store/home';

enum ViewMode {
  Pending = 1,
  Past = 2,
  Canceled = 3
}

@Component({
  components: {
    EditTable
  },
})
export default class CodesOrders extends Vue {

    ViewMode = ViewMode;

    private columns: TableColumnDefinition[] = [
        { propertyName: 'code', label: 'view.cart.code' },
        { propertyName: 'orders.length', label: 'view.codesOrders.ordersNb' },
        { propertyName: 'totalPrice', label: 'view.account.total', type: 'Money' },
    ];
    private seasons: Season[] = [];
    private selectedSeason: Season = ({} as any);

    mounted(): void {
        if (!this.manager) {
          this.$router.push('/');
        }

        this.initSeasons();

        admin.dispatchLoadOrders();
        home.dispatchLoadDatas();
    }

    initSeasons(): void {
      const startYear = 2021;
      const date = new Date();
      const currentYear = date.getFullYear() - (date.getMonth() <= 6 ? 1 : 0);
      const years = [...Array(currentYear - startYear).keys()];
      for (let i = years.length; i >= 0; i--) {
        const currentSeason = startYear + i;
        this.seasons.push({
          id: i.toString(),
          name: currentSeason + '/' + (currentSeason + 1),
          startDate: new Date(currentSeason, 8, 1),
          endDate: new Date(currentSeason + 1, 7, 30),
          active: true
        });
      }

      this.selectedSeason = this.seasons[0];
    }

    getOrderStatus(order: Order): string {
        if (order.endedOn) {
            return 'canceled';
        }

        const rentDates = order.orderProducts.map(p => p.endDate);
        const maxDate = rentDates.reduce((a, b) => a > b ? a : b);
        const d = new Date();
        const currentDate = new Date(d.getFullYear(), d.getMonth(), d.getDate());

        if (maxDate < currentDate) {
            return 'past';
        }

        return 'booked';
    }

    get codesSummary(): CodeOrders[] {
      let orders = admin.orders.filter(o => !o.endedOn && o.promotionCode && (o.depositState === DepositState.None || o.depositState === DepositState.Successfull));

      if (this.selectedSeason && this.selectedSeason.name) {
        orders = orders.filter(o => o.orderProducts && o.orderProducts.length ?
          o.orderProducts[0].startDate >= this.selectedSeason.startDate && o.orderProducts[0].endDate <= this.selectedSeason.endDate :
          o.createdOn >= this.selectedSeason.startDate && o.createdOn <= this.selectedSeason.endDate);
      }

      const result = orders.reduce((prev, curr) => {
        const code = prev.find(p => p.code === curr.promotionCode!.code);
        if (code) {
          code.orders.push(curr);
          code.totalPrice += curr.price;
        } else {
          prev.push({
            code: curr.promotionCode!.code,
            orders: [curr],
            totalPrice: curr.price
          });
        }
        return prev;
      }, [] as CodeOrders[]);

      result.forEach(r => {
        r.totalPrice = Math.round(r.totalPrice * 100) / 100;
      });

      return result.sort((a, b) => a.totalPrice > b.totalPrice ? -1 : a.totalPrice < b.totalPrice ? 1 : 0);
    }

    get manager(): boolean {
      return !!user.currentUser && !!user.currentUser.roles
        && (user.currentUser.roles.indexOf("Manager") !== -1 || user.currentUser.roles.indexOf("Admin") !== -1);
    }
}
