


































































































import { Component, Vue } from 'vue-property-decorator';
import { Accessory } from '../../models/model';
import admin from '../../store/admin';
import RentPrices from '../../components/Admin/RentPrices.vue';
import PictureUpload from '../../components/Admin/PictureUpload.vue';
import toaster, { ToastImpl } from '@/store/toaster';

interface Size {
    id: number;
    value: number;
}

@Component({ components: { RentPrices, PictureUpload } })
export default class EditAccessory extends Vue {

    private sizes: Size[] = [];

    mounted(): void {
        if (!this.editedAccessory) {
            this.$router.push('/Admin/Accessories');
        }

        admin.editedAccessory = {...this.editedAccessory!,
            pictures: this.editedAccessory!.pictures.slice(),
            sizes: this.editedAccessory!.sizes.slice(),
            rentPrices: this.editedAccessory!.rentPrices.slice()};

        this.sizes = this.editedAccessory!.sizes.map((s, i) => { return { id: i, value: s }; });
    }

    addSize(): void {
        const ids = this.sizes.map(s => s.id);
        this.sizes.push({ id: ids.length ? Math.max(...ids) + 1 : 1, value: 0 });
    }

    removeSize(id: number): void {
        this.sizes = this.sizes.filter(s => s.id !== id);
    }

    cancel(): void {
        admin.editedAccessory = undefined;
        this.$router.push('/Admin/Accessories');
    }

    save(): void {
        if (this.editedAccessory!.hasSize) {
            this.editedAccessory!.sizes = this.sizes.map(s => s.value).sort();
        } else {
            this.editedAccessory!.sizes = [];
        }
        admin.dispatchSaveAccessory(this.editedAccessory!).then(() => {
            toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.saved')}`, `${this.$t('common.accessorySaved')}`));
            this.cancel();
        });
    }

    remove(): void {
        admin.dispatchDeleteAccessory(this.editedAccessory!.id).then(() => {
            toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.removed')}`, `${this.$t('common.accessoryRemoved')}`));
            this.cancel();
        });
    }

    get editedAccessory(): Accessory | undefined {
        return admin.editedAccessory;
    }

    get canSave(): boolean {
        return !!this.editedAccessory &&
            !!this.editedAccessory.label &&
            !!this.editedAccessory.labelEn &&
            (!this.editedAccessory.hasSize || this.sizes.length > 1) &&
            this.editedAccessory.rentPrices.filter(r => r.price > 0).length === this.editedAccessory.rentPrices.length;
    }

    get isNew(): boolean {
        return !this.editedAccessory || !this.editedAccessory.id;
    }

}
