














































import { Component, Vue } from 'vue-property-decorator';
import { User } from '../../models/model';
import admin from '../../store/admin';
import toaster, { ToastImpl } from '@/store/toaster';
import BasicInfo from '@/components/Account/BasicInfo.vue';
import Address from '@/components/Account/Address.vue';

@Component({
  components: {
    BasicInfo,
    Address
  },
})
export default class EditUser extends Vue {

    mounted(): void {
        if (!this.editedUser) {
            this.$router.push('/Admin/Users');
        }

        admin.dispatchLoadRoles();

        admin.editedUser = {...this.editedUser!};
    }

    cancel(): void {
        admin.editedUser = undefined;
        this.$router.push('/Admin/Users');
    }

    save(): void {
      admin.dispatchAddRole({userId: this.editedUser!.id, role: this.role}).then(() => {
        toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.saved')}`, `${this.$t('common.userSaved')}`));
        this.cancel();
      });
      // admin.dispatchSaveUser(this.editedUser!).then(() => {
      //   toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.saved')}`, `${this.$t('common.userSaved')}`));
      //   this.cancel();
      // });
    }

    remove(): void {
        admin.dispatchDeleteUser(this.editedUser!.id).then(() => {
          toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.removed')}`, `${this.$t('common.userRemoved')}`));
          this.cancel();
        });
    }

    get role(): string | undefined {
      return this.editedUser && this.editedUser.roles && this.editedUser.roles.length
        ? this.editedUser.roles[0]
        : undefined;
    }

    set role(value: string | undefined) {
      if (this.editedUser) {
        if (value) {
          this.editedUser.roles = [value];
        } else {
          this.editedUser.roles = [];
        }
      }
    }

    get roles(): string[] {
      return admin.roles;
    }

    get editedUser(): User | undefined {
        return admin.editedUser;
    }
}
