
















































































import { Picture } from '@/models/model';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Api, { Error } from '@/api/api';
import toaster, { ToastImpl } from '@/store/toaster';

@Component
export default class PictureUpload extends Vue {

    @Prop()
    pictures!: Picture[];

    private dragover = false;
    private uploading = false;
    private timeout = 0;

    getPictureUrl(picture: Picture): string {
        return Api.apiUrl + 'v1/document/picture/' + picture.hash + '?fileName=' + picture.name;
    }

    getDocumentSize(size: number): string {
        const kSize = size / 1024;
        const mSize = kSize / 1024;
        const gSize = mSize / 1024;
        if (gSize > 1) {
            return `${gSize.toFixed(2)} Go`;
        } else if (mSize > 1) {
            return `${mSize.toFixed(2)} Mo`;
        } else if (kSize > 1) {
            return `${kSize.toFixed(2)} Ko`;
        }
        return `${size} octets`;
    }

    uploadFile(event: DragEvent): void {
        let files: FileList = (event.target as any).files;
        if (!files) {
            files = event.dataTransfer!.files;
        }
        if (files && files.length > 0) {
            const file = files[0];
            this.uploading = true;
            Api.uploadDocument(file).then((r: Error & string) => {
                if (!r.error) {
                    const picture: Picture = { hash: r, name: file.name, title: file.name, createdOn: new Date(), size: file.size, description: '' };
                    this.pictures.push(picture);
                } else {
                    const maxFileSize = 51200;
                    if (file.size > maxFileSize) {
                        toaster.dispatchToast(new ToastImpl('danger', `${this.$t('common.error')}`, `${this.$t('common.maxSizeError')}`));
                    } else {
                        toaster.dispatchToast(new ToastImpl('danger', `${this.$t('common.error')}`, `${this.$t('common.uploadError')}`));
                    }
                }
                this.uploading = false;
            });
        }
    }

    removePicture(picture: Picture): void {
        const index = this.pictures.findIndex(d => d.hash === picture.hash);
        this.pictures.splice(index, 1);
    }

    draggedOver(): void {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        that.dragover = true;
        if (that.timeout) {
            clearTimeout(that.timeout);
        }
        that.timeout = setTimeout(() => {
            that.dragover = false;
        }, 250);
    }

}
