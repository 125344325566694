






















































































import { Component, Vue } from 'vue-property-decorator';
import user from '@/store/user';
import InputValidator from "@/helper/inputValidator";
import toaster, { ToastImpl } from '@/store/toaster';

@Component
export default class Password extends Vue {

    private oldPassword = "";
    private password = "";
    private confirmPassword = "";

    private error = false;
    private invalidPassword = false;
    private invalidConfirmPassword = false;

    private showOldPassword = false;
    private showPassword = false;
    private showConfirmPassword = false;

    mounted(): void {
        this.init();
    }

    init(): void {
        this.oldPassword = '';
        this.password = '';
        this.confirmPassword = '';
        this.error = false;
        this.invalidPassword = false;
        this.invalidConfirmPassword = false;
        this.showOldPassword = false;
        this.showPassword = false;
        this.showConfirmPassword = false;
    }

    toggleShowPassword(property: string): void {
        (this as any)[property] = !(this as any)[property];
    }

    save(): void {
        this.invalidPassword = !InputValidator.checkPassword(this.password);
        this.invalidConfirmPassword = !InputValidator.checkConfirmPassword(this.password, this.confirmPassword);

        if (this.invalidPassword || this.invalidConfirmPassword) {
            return;
        }

        user.dispatchChangePassword({ oldPassword: this.oldPassword, newPassword: this.password, confirmPassword: this.confirmPassword }).then((r: boolean) => {
            if (r) {
                toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.saved')}`, `${this.$t('common.passwordSaved')}`));
                this.init();
            } else {
                this.error = true;
            }
        });
    }

    get canSave(): boolean {
        return !!this.oldPassword && !!this.password && !!this.confirmPassword;
    }

}
