
















































































































































import { Component, Vue } from 'vue-property-decorator';
import languages from '@/helper/languages';
import Order, { Language } from '@/models/model';
import user from '@/store/user';
import order from '@/store/order';
import moment from 'moment';

@Component
export default class Footer extends Vue {

    selectLang(lang: string): void {
        this.$root.$i18n.locale = lang;
        moment.locale(lang);
    }

    getSelectedLanguageKey(): string | undefined {
        return this.languages.find(l => l.id === this.selectedLanguage)?.nameKey;
    }

    getImgUrl(key: string): void {
        return require(`../assets/${key}.svg`);
    }

    signout(): void {
      user.dispatchSignout().then(() => this.$router.push('/'));
    }

    get languages(): Language[] {
        return languages.getLanguages();
    }

    get selectedLanguage(): string {
        return this.$root.$i18n.locale;
    }

    get order(): Order | undefined {
      return order.currentOrder;
    }

    get noMargin(): boolean {
        return this.$route.name === 'Informations';
    }

}
