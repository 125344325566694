




























import { Component, Vue } from 'vue-property-decorator';
import EditTable from '@/components/Admin/EditTable.vue';
import { GlobalPromotion, TableColumnDefinition } from '@/models/model';
import admin from '@/store/admin';
import user from '@/store/user';

@Component({
  components: {
    EditTable
  },
})
export default class Promotions extends Vue {

    private columns: TableColumnDefinition[] = [
        { propertyName: 'label', label: 'Name' },
        { propertyName: 'labelEn', label: 'Name (en)' },
        { propertyName: 'startDate', label: 'Start date' },
        { propertyName: 'endDate', label: 'End date' },
        { propertyName: 'promotion', label: 'Promotion (%)' },
        { propertyName: 'minRentDays', label: 'Min rent days'}
    ];

    mounted(): void {
      if (!this.admin) {
        this.$router.push('/');
      }

      admin.dispatchLoadPromotions();
    }

    addPromotion(): void {
        admin.editedPromotion = ({label: '', labelEn: '', startDate: '', endDate: '', promotion: 0, minRentDays: 0 } as any);
        this.$router.push('/Admin/Promotions/Edit');
    }

    get promotions(): GlobalPromotion[] {
        return admin.promotions.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0);
    }

    get admin(): boolean {
      return !!user.currentUser && !!user.currentUser.roles && user.currentUser.roles.indexOf("Admin") !== -1;
    }
}
