
































import { Component, Vue } from 'vue-property-decorator';
import CartProducts from '@/components/Cart/CartProducts.vue';
import OrderSummary from '@/components/Cart/OrderSummary.vue';
import order from '@/store/order';
import Order from '@/models/model';

@Component({
  components: {
    CartProducts,
    OrderSummary
  },
})
export default class ShoppingCart extends Vue {

    mounted(): void {
        if (!this.currentOrder) {
            this.$router.push('/');
        }
    }

    get currentOrder(): Order | undefined {
        return order.currentOrder;
    }

}
