


















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { User } from '@/models/model';
import user from '@/store/user';
import InputValidator from "@/helper/inputValidator";
import toaster, { ToastImpl } from '@/store/toaster';

@Component
export default class BasicInfo extends Vue {

    private editedUser: User = ({} as any);
    private invalidEmail = false;

    @Prop()
    user!: User;

    @Prop()
    readonly?: boolean;

    @Watch('user')
    userChanged(): void {
        this.init();
    }

    mounted(): void {
        this.init();
    }

    init(): void {
        this.editedUser = {...this.user!};
    }

    save(): void {
        this.invalidEmail = !InputValidator.checkInvalidEmail(this.editedUser.email);

        if (this.invalidEmail) {
            return;
        }

        user.dispatchSaveUser(this.editedUser).then(() => {
            toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.saved')}`, `${this.$t('common.userInfoSaved')}`));
            user.dispatchLoadCurrentUser();
        });
    }

    get canSave(): boolean {
        return !!this.editedUser.firstName && !!this.editedUser.lastName && !!this.editedUser.email && !!this.editedUser.gender && !!this.editedUser.phoneNumber;
    }

}
