

























































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Accessory, DepositState, Order, OrderProduct, Product, ProductType } from '@/models/model';
import admin from '@/store/admin';
import user from '@/store/user';
import home from '@/store/home';
import OrderDetails from '@/components/Admin/OrderDetails.vue';

enum CalendarType {
  Occupation = 1,
  In = 2,
  Out = 3
}

enum ViewMode {
  Detailled = 1,
  Order = 2,
  Product = 3
}

@Component({components: {OrderDetails}})
export default class Dashboard extends Vue {

    ProductType = ProductType;
    CalendarType = CalendarType;
    ViewMode = ViewMode;

    private date: Date = new Date();
    private productTypes: string[] = [];
    private productsByTypeDateIn: any = {};
    private productsByTypeDateOut: any = {};
    private productsNbByTypeDateIn: any = {};
    private productsNbByTypeDateOut: any = {};
    private ordersDateIn: Order[] = [];
    private ordersDateOut: Order[] = [];
    private noProductsIn = false;
    private noProductsOut = false;
    private selectedOrder: Order | undefined = ({} as any);

    mounted(): void {
      if (!this.manager) {
        this.$router.push('/');
      }

      home.dispatchLoadDatas().then(() => {
        this.productTypes = Object.keys(ProductType).filter(k => !isNaN(Number(k)));

        admin.dispatchLoadOrders().then(() => {
          const date = new Date();
          date.setDate(date.getDate() + 1);
          date.setDate(date.getDate() - 1);
          this.date = date;

          this.initDate();
        });
      });
    }

    private initDate(): void {
      this.noProductsIn = true;
      this.noProductsOut = true;
      this.productTypes.forEach(t =>
        {
          const products = this.products.filter(p => p.type == t as any)
            .sort((a, b) => a.rentPrices[0].price > b.rentPrices[0].price ? -1 : a.rentPrices[0].price < b.rentPrices[0].price ? 1 : 0);

          this.productsByTypeDateIn[t] = products.map(p => this.getDateOrderProducts(p, CalendarType.In)).reduce((a, b) => a.concat(b), []);
          this.productsByTypeDateOut[t] = products.map(p => this.getDateOrderProducts(p, CalendarType.Out)).reduce((a, b) => a.concat(b), []);

          this.productsNbByTypeDateIn[t] = products.map(p => { return { product: p, nb: this.getDateOrderProducts(p, CalendarType.In).length }; }).filter(p => p.nb > 0);
          this.productsNbByTypeDateOut[t] = products.map(p => { return { product: p, nb: this.getDateOrderProducts(p, CalendarType.Out).length }; }).filter(p => p.nb > 0);

          if (this.productsByTypeDateIn[t].length) {
            this.noProductsIn = false;
          }
          if (this.productsByTypeDateOut[t].length) {
            this.noProductsOut = false;
          }
        }
      );

      this.ordersDateIn = this.getDateOrders(CalendarType.In);
      this.ordersDateOut = this.getDateOrders(CalendarType.Out);
    }

    private nextDate(): void {
      var date = new Date(this.date.valueOf());
      date.setDate(date.getDate() + 1);
      this.date = date;
      this.initDate();
    }

    private previousDate(): void {
      var date = new Date(this.date.valueOf());
      date.setDate(date.getDate() - 1);
      this.date = date;
      this.initDate();
    }

    private getLabel(productType: ProductType, label: string): string {
      if (productType == ProductType.Boots) {
        return label.split(' ').filter(s => s !== 'seules' && s !== 'only').join(' ');
      }

      return label;
    }

    private getAccessoryLabel(id: string): string {
        const accessory = this.accessories.find(a => a.id === id);
        return accessory ? accessory['label' + this.langSuffix] : 'Unknown';
    }

    private showDetails(orderProduct: OrderProduct): void {
      this.showOrderDetails(this.orders.find(o => o.orderProducts.indexOf(orderProduct) !== -1)!);
    }

    private showOrderDetails(order: Order): void {
      this.selectedOrder = order;
      var modal = (window as any).bootstrap.Modal.getOrCreateInstance(this.$refs.orderModal);
      modal.show();
    }

    private getUserNameOrder(product: OrderProduct): string {
      const order: any = this.orders.find(o => o.orderProducts.indexOf(product) !== -1);
      if (order) {
        return order.user.firstName + ' ' + order.user.lastName;
      }
      return 'Unknown';
    }

    private getDateOrderProducts(product: Product, calendarType: CalendarType): {order: OrderProduct, product: Product, mainProduct: boolean}[] {
      const dateOnly = this.getDateOnly(this.date);
      let productsRent = this.orders.filter(o => !o.endedOn).reduce((a, b) => a.concat(b.orderProducts), [] as OrderProduct[]);
      if (calendarType == CalendarType.Occupation) {
        productsRent = productsRent.filter(p => this.getDateOnly(p.startDate) <= dateOnly && this.getDateOnly(p.endDate) >= dateOnly);
      } else if (calendarType == CalendarType.In) {
        productsRent = productsRent.filter(p => this.dateEquals(p.endDate, this.date));
      } else {
        productsRent = productsRent.filter(p => this.dateEquals(p.startDate, this.date));
      }
      return productsRent.filter(p => p.productId == product.id || (p.package && p.package.additionalProductIds && p.package.additionalProductIds.indexOf(product.id) !== -1))
        .map(p => { return {order: p, product, mainProduct: p.productId == product.id}; });
    }

    private getDateOrders(calendarType: CalendarType): Order[] {
      const dateOnly = this.getDateOnly(this.date);
      let orders = this.orders.filter(o => !o.endedOn);
      if (calendarType == CalendarType.Occupation) {
        orders = orders.filter(o => {
          const orderProducts = o.orderProducts.filter(p => this.getDateOnly(p.startDate) <= dateOnly && this.getDateOnly(p.endDate) >= dateOnly);
          if (orderProducts.length) {
            (o as any).nbByProducts = this.products.map(p => { return { product: p, nb: this.getOrderProducts(o, p).length }; })
              .filter(p => p.nb > 0);
          }
          return orderProducts.length > 0;
        });
      } else if (calendarType == CalendarType.In) {
        orders = orders.filter(o => {
          const orderProducts = o.orderProducts.filter(p => this.dateEquals(p.endDate, this.date));
          if (orderProducts.length) {
            (o as any).nbByProducts = this.products.map(p => { return { product: p, nb: this.getOrderProducts(o, p).length }; })
              .filter(p => p.nb > 0);
          }
          return orderProducts.length > 0;
        });
      } else {
        orders = orders.filter(o => {
          const orderProducts = o.orderProducts.filter(p => this.dateEquals(p.startDate, this.date));
          if (orderProducts.length) {
            (o as any).nbByProducts = this.products.map(p => { return { product: p, nb: this.getOrderProducts(o, p).length }; })
              .filter(p => p.nb > 0);
          }
          return orderProducts.length > 0;
        });
      }

      return orders;
    }

    private getOrderProducts(order: Order, product: Product): OrderProduct[] {
      let productsRent = order.orderProducts;
      return productsRent.filter(p => p.productId == product.id || (p.package && p.package.additionalProductIds && p.package.additionalProductIds.indexOf(product.id) !== -1));
    }

    private dateEquals(date1: Date, date2: Date): boolean {
      return date1.getFullYear() == date2.getFullYear() && date1.getMonth() == date2.getMonth() && date1.getDate() == date2.getDate();
    }

    private getDateOnly(date: Date): Date {
      return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
    }

    get orders(): Order[] {
      return admin.orders.filter(o => o.depositState === DepositState.None || o.depositState === DepositState.Successfull);
    }

    get products(): Product[] {
      return home.products;
    }

    get loading(): boolean {
      return admin.loading;
    }

    get langSuffix(): string {
        return this.$root.$i18n.locale === 'en' ? 'En' : '';
    }

    get manager(): boolean {
      return !!user.currentUser && !!user.currentUser.roles
        && (user.currentUser.roles.indexOf("Manager") !== -1 || user.currentUser.roles.indexOf("Admin") !== -1);
    }

    get accessories(): Accessory[] {
        return home.accessories;
    }
}
