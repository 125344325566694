

















































































































































import { Accessory, GlobalPromotion, Order, OrderProduct, Picture, PromotionCode, User } from '@/models/model';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Api from '@/api/api';
import user from '@/store/user';
import home from '@/store/home';
import order from '@/store/order';
import PriceCalculator from '@/helper/priceCalculator';

@Component
export default class OrderSummary extends Vue {

    @Prop()
    order!: Order;

    @Prop()
    details!: boolean;

    @Prop()
    readonly!: boolean;

    private promoCodeStr = '';
    private promoCodeError = false;

    getPrice(orderProduct: OrderProduct): number {
        return PriceCalculator.getPrice(this.order, orderProduct);
    }

    getAccessoryLabel(id: string): string {
        const accessory = this.accessories.find(a => a.id === id);
        return accessory ? accessory['label' + this.langSuffix] : 'Unknown';
    }

    getPictureUrl(picture: Picture): string {
        return Api.apiUrl + 'v1/document/picture/' + picture.hash + '?fileName=' + picture.name;
    }

    cancelCode(): void {
        this.promoCodeStr = '';
        this.promoCodeError = false;
    }

    applyCode(forceOverride = false): void {
        if (this.promoCodeStr) {
            if (this.order.globalPromotionId && !forceOverride) {
                var modal = (window as any).bootstrap.Modal.getOrCreateInstance(this.$refs.codeConfirmModal);
                modal.show();
                return;
            }
            this.promoCodeError = false;
            order.dispatchCheckCode(this.promoCodeStr).then((r: PromotionCode | undefined) => {
                this.promoCodeStr = '';
                if (r && r.id) {
                    if (PriceCalculator.checkCodeApplicable(this.order, r)) {
                        this.order.globalPromotion = undefined;
                        this.order.globalPromotionId = '';
                        this.order.promotionCode = r;
                        this.order.promotionCodeId = r.id;
                        order.commitSetCurrentOrder(this.order);
                    } else {
                        console.log('Not applicable');
                        this.promoCodeError = true;
                    }
                } else {
                    this.promoCodeError = true;
                }
            })
        }
    }

    checkout(): void {
        if (!this.loggedIn) {
            var modal = (window as any).bootstrap.Modal.getOrCreateInstance(this.$refs.loginModal);
            modal.show();
        }
        else {
            this.$router.push('/Cart/Checkout');
        }
    }

    goTo(path: string): void {
        user.redirectRoute = '/Cart/Checkout';
        this.$router.push(path);
    }

    get totalPrice(): number {
        return PriceCalculator.getTotalPrice(this.order);
    }

    get totalPriceBeforePromotions(): number {
        return PriceCalculator.getTotalPriceBeforePromotions(this.order);
    }

    get globalPromotion(): GlobalPromotion | undefined {
        return PriceCalculator.getGlobalPromotion(this.order);
    }

    get promotionCode(): PromotionCode | undefined {
        return this.order.promotionCode;
    }

    get globalPromotionsMoney(): number {
        return PriceCalculator.getGlobalPromotionsMoney(this.order);
    }

    get promotionCodeMoney(): number {
        return PriceCalculator.getPromotionCodeMoney(this.order);
    }

    get deposit(): number {
        return PriceCalculator.getDeposit(this.order);
    }

    get langSuffix(): string {
        return this.$root.$i18n.locale === 'en' ? 'En' : '';
    }

    get loggedIn(): boolean {
      return user.loggedIn;
    }

    get currentUser(): User | undefined {
      return user.currentUser;
    }

    get accessories(): Accessory[] {
        return home.accessories;
    }
}
