



















































































































































import { Component, Vue } from 'vue-property-decorator';
import { DateRange, Product } from '../../models/model';
import admin from '../../store/admin';
import RentPrices from '../../components/Admin/RentPrices.vue';
import Packages from '../../components/Admin/Packages.vue';
import PictureUpload from '../../components/Admin/PictureUpload.vue';
import CustomDatePicker from '@/components/Shared/CustomDatePicker.vue';
import toaster, { ToastImpl } from '@/store/toaster';

@Component({ components: { RentPrices, Packages, PictureUpload, CustomDatePicker } })
export default class EditProduct extends Vue {

    private colors = ['black', 'body', 'warning', 'secondary', 'danger', 'dark', 'info', 'primary', 'success'];

    mounted(): void {
        if (!this.editedProduct) {
            this.$router.push('/Admin/Products');
        }

        admin.editedProduct = {...this.editedProduct!,
            pictures: this.editedProduct!.pictures.slice(),
            rentPrices: this.editedProduct!.rentPrices.slice(),
            packages: this.editedProduct!.packages.slice(),
            unavailabilityDates: this.editedProduct!.unavailabilityDates ? this.editedProduct!.unavailabilityDates.slice() : []
        };
    }

    cancel(): void {
        admin.editedProduct = undefined;
        this.$router.push('/Admin/Products');
    }

    addDate(): void {
      this.editedProduct!.unavailabilityDates.push({startDate: '' as any, endDate: '' as any});
    }

    removeDate(date: DateRange): void {
      this.editedProduct!.unavailabilityDates = this.editedProduct!.unavailabilityDates.filter(d => d != date);
    }

    save(): void {
        admin.dispatchSaveProduct(this.editedProduct!).then(() => {
            toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.saved')}`, `${this.$t('common.productSaved')}`));
            this.cancel();
        });
    }

    remove(): void {
        admin.dispatchDeleteProduct(this.editedProduct!.id).then(() => {
            toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.removed')}`, `${this.$t('common.productRemoved')}`));
            this.cancel();
        });
    }

    get editedProduct(): Product | undefined {
        return admin.editedProduct;
    }

    get canSave(): boolean {
        return !!this.editedProduct &&
            !!this.editedProduct.label &&
            !!this.editedProduct.labelEn &&
            !!this.editedProduct.description &&
            !!this.editedProduct.descriptionEn &&
            !!this.editedProduct.type &&
            this.editedProduct.rentPrices.filter(r => r.price > 0).length === this.editedProduct.rentPrices.length &&
            this.editedProduct.packages.filter(r => !!r.label && !!r.labelEn && !!r.additionalProductIds && r.additionalProductIds.length > 0 && !!r.rentPrices && r.rentPrices.filter(rr => rr.price > 0).length === r.rentPrices.length).length === this.editedProduct.packages.length;
    }

    get isNew(): boolean {
        return !this.editedProduct || !this.editedProduct.id;
    }

}
