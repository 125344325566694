import { Language } from "@/models/model";

export default class Languages {

    private static languages: Language[] = [
        {
            id: 'en',
            nameKey: 'language.english',
        },
        {
            id: 'fr',
            nameKey: 'language.french'
        }
    ];

    public static getLanguages(): Language[] {
        return this.languages;
    }

}