




























import { Component, Vue } from 'vue-property-decorator';
import EditTable from '@/components/Admin/EditTable.vue';
import { Accessory, TableColumnDefinition } from '@/models/model';
import admin from '@/store/admin';
import home from '@/store/home';
import RentPricesHelper from '@/helper/rentPricesHelper';
import user from '@/store/user';

@Component({
  components: {
    EditTable
  },
})
export default class Accessories extends Vue {

    private columns: TableColumnDefinition[] = [
        { propertyName: 'label', label: 'Name' },
        { propertyName: 'labelEn', label: 'Name (en)' },
        { propertyName: 'hasSize', label: 'Has Size' },
        { propertyName: 'sizes', label: 'Sizes' },
        { propertyName: 'pictures.length', label: 'Pictures' }
    ];

    mounted(): void {
      if (!this.admin) {
        this.$router.push('/');
      }

      home.dispatchLoadDatas();
    }

    addAccessory(): void {
        admin.editedAccessory = ({label: '', labelEn: '', hasSize: false, sizes: [], pictures: [], rentPrices: RentPricesHelper.generateRentPrices() } as any);
        this.$router.push('/Admin/Accessories/Edit');
    }

    get accessories(): Accessory[] {
        return home.accessories.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0);
    }

    get admin(): boolean {
      return !!user.currentUser && !!user.currentUser.roles && user.currentUser.roles.indexOf("Admin") !== -1;
    }
}
