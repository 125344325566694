import { Toast } from "../models/model";
import { ToasterState, RootState } from "../models/state";
import { BareActionContext, getStoreBuilder } from "vuex-typex";

export class ToastImpl implements Toast {

    private icons: any = {
        'success': 'bi-check',
        'info': 'bi-info',
        'danger': 'bi-exclamation',
        'warning': 'bi-exclamation-triangle'
    };

    icon: string;
    id: string;

    constructor(public type: string, public title: string, public description: string) {
        this.icon = this.icons[type];
        this.id = this.newGuid();
    }

    private newGuid(): string {
        const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

}

const initialToasterState: ToasterState = {
    toasts: []
};
const b = getStoreBuilder<RootState>().module("toaster", initialToasterState);

const toastsGetter = b.read((state: ToasterState) => state.toasts, "toasts");

function addToast(state: ToasterState, payload: Toast): void {
    state.toasts = [...state.toasts, payload];
}

function removeToast(state: ToasterState, payload: string): void {
    state.toasts = state.toasts.filter(t => t.id !== payload);
}

function createToast(context: BareActionContext<ToasterState, RootState>, toast: Toast): Promise<boolean>
{
    toaster.commitAddToast(toast);
    setTimeout(() => toaster.commitRemoveToast(toast.id), 6000);

    return new Promise((resolve) => {
        resolve(true);
    });
}

const stateGetter = b.state()

const toaster = {
    get state(): ToasterState { return stateGetter() },

    get toasts(): Toast[] { return toastsGetter() },

    commitAddToast: b.commit(addToast),
    commitRemoveToast: b.commit(removeToast),

    dispatchToast: b.dispatch(createToast)
}

export default toaster