








import { Component, Vue } from 'vue-property-decorator';
import Account from '@/components/Account/Account.vue'
import UserOrders from '@/components/Account/UserOrders.vue';
import { User } from '@/models/model';
import user from '@/store/user';

@Component({
  components: {
    Account,
    UserOrders
  },
})
export default class Orders extends Vue {

    mounted(): void {
        if (!this.currentUser) {
            this.$router.push('/');
        }
    }

    get currentUser(): User | undefined {
        return user.currentUser;
    }

}
