


























import { RentPrice } from '@/models/model';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RentPrices extends Vue {

    @Prop()
    prices!: RentPrice[];

}
