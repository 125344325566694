































































import { Component, Vue } from 'vue-property-decorator';
import order from '@/store/order';
import Order from '@/models/model';
import OrderCard from './OrderCard.vue';
import OrderEmptyState from './OrderEmptyState.vue';

@Component({ components: { OrderCard, OrderEmptyState } })
export default class UserOrders extends Vue {

    mounted(): void {
        this.init();
    }

    init(): void {
        order.dispatchLoadOrders();
    }

    getOrderStatus(order: Order): string {
        if (order.endedOn) {
            return 'canceled';
        }

        const rentDates = order.orderProducts.map(p => p.endDate);
        const maxDate = rentDates.reduce((a, b) => a > b ? a : b);
        const d = new Date();
        const currentDate = new Date(d.getFullYear(), d.getMonth(), d.getDate());

        if (maxDate < currentDate) {
            return 'past';
        }

        return 'booked';
    }

    get orders(): Order[] {
        return order.orders.sort((a, b) => a.number > b.number ? -1 : a.number < b.number ? 1 : 0);
    }

    get filtredOrders(): Order[] {
        return this.orders;
    }

    get activeOrders(): Order[] {
        return this.orders.filter(o => this.getOrderStatus(o) === 'booked');
    }

    get pastOrders(): Order[] {
        return this.orders.filter(o => this.getOrderStatus(o) === 'past');
    }

    get canceledOrders(): Order[] {
        return this.orders.filter(o => this.getOrderStatus(o) === 'canceled');
    }
}
