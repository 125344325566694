import { Product, Accessory, GlobalPromotion, Banner, Season } from "../models/model";
import { HomeState, RootState } from "../models/state";
import { getStoreBuilder } from "vuex-typex";
import Api, { convertJsonDates } from "@/api/api";

const initialHomeState: HomeState = {
    products: [],
    accessories: [],
    promotions: [],
    seasons: [],
    banner: undefined,
    loading: false
};
const b = getStoreBuilder<RootState>().module("home", initialHomeState);

const loadingGetter = b.read((state: HomeState) => state.loading, "loading");
const productsGetter = b.read((state: HomeState) => state.products, "products");
const accessoriesGetter = b.read((state: HomeState) => state.accessories, "accessories");
const promotionsGetter = b.read((state: HomeState) => state.promotions, "promotions");
const seasonsGetter = b.read((state: HomeState) => state.seasons, "seasons");
const bannerGetter = b.read((state: HomeState) => state.banner, "banner");

function setLoading(state: HomeState, payload: boolean): void
{
    state.loading = payload;
}

function setProducts(state: HomeState, payload: Product[]): void
{
    state.products = payload;
}

function setAccessories(state: HomeState, payload: Accessory[]): void
{
    state.accessories = payload;
}

function setPromotions(state: HomeState, payload: GlobalPromotion[]): void
{
    state.promotions = payload;
}

function setSeasons(state: HomeState, payload: Season[]): void
{
    state.seasons = payload;
}

function setBanner(state: HomeState, payload: Banner | undefined): void
{
    state.banner = payload;
}

async function loadDatas(): Promise<boolean>
{
    home.loading = true;
    const loadedDatas = await Api.getDatas();
    home.loading = false;

    convertJsonDates(loadedDatas, ['startDate', 'endDate']);

    loadedDatas.products.forEach((p: Product) => {
        p.selectedPackage = -1;
        p.shownPicture = 0;
    });

    loadedDatas.accessories.forEach((a: Accessory) => {
        a.selected = false;
        a.selectedSize = 0;
    });

    home.products = loadedDatas.products;
    home.accessories = loadedDatas.accessories;
    home.promotions = loadedDatas.promotions;
    home.banner = loadedDatas.banner;
    home.seasons = loadedDatas.seasons;

    return true;
}

const stateGetter = b.state()

const home = {
    get state(): HomeState { return stateGetter() },

    get loading(): boolean { return loadingGetter() },
    set loading(value: boolean) { this.commitSetLoading(value) },

    get products(): Product[] { return productsGetter() },
    set products(value: Product[]) { this.commitSetProducts(value); },

    get accessories(): Accessory[] { return accessoriesGetter() },
    set accessories(value: Accessory[]) { this.commitSetAccessories(value); },

    get promotions(): GlobalPromotion[] { return promotionsGetter() },
    set promotions(value: GlobalPromotion[]) { this.commitSetPromotions(value); },

    get seasons(): Season[] { return seasonsGetter() },
    set seasons(value: Season[]) { this.commitSetSeasons(value); },

    get banner(): Banner | undefined { return bannerGetter() },
    set banner(value: Banner | undefined) { this.commitSetBanner(value); },

    commitSetLoading: b.commit(setLoading),
    commitSetProducts: b.commit(setProducts),
    commitSetAccessories: b.commit(setAccessories),
    commitSetPromotions: b.commit(setPromotions),
    commitSetSeasons: b.commit(setSeasons),
    commitSetBanner: b.commit(setBanner),

    dispatchLoadDatas: (b.dispatch(loadDatas) as (param?: any) => Promise<boolean>)
}

export default home