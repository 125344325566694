/* eslint-disable */
export const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import( /* webpackChunkName: "Home" */ '../views/Home.vue'),
        meta: {
            sitemap: {
                priority: 1
            }
        }
    },
    {
        path: '/Info',
        name: 'Informations',
        component: () => import( /* webpackChunkName: "Informations" */ '../views/Informations.vue')
    },
    {
        path: '/Partners',
        name: 'Partners',
        component: () => import( /* webpackChunkName: "Partners" */ '../views/Partners.vue')
    },
    {
        path: '/Legal',
        name: 'LegalMentions',
        component: () => import( /* webpackChunkName: "LegalMentions" */ '../views/LegalMentions.vue'),
        meta: {
            sitemap: {
                priority: 0.2
            }
        }
    },
    {
        path: '/CGL',
        name: 'CGU',
        component: () => import( /* webpackChunkName: "CGU" */ '../views/CGU.vue'),
        meta: {
            sitemap: {
                priority: 0.2
            }
        }
    },
    {
        path: '/Login',
        name: 'Login',
        component: () => import( /* webpackChunkName: "Login" */ '../views/Login.vue')
    },
    {
        path: '/SignUp',
        name: 'SignUp',
        component: () => import( /* webpackChunkName: "SignUp" */ '../views/SignUp.vue')
    },
    {
        path: '/Rent/AdultSkis',
        name: 'AdultSkis',
        component: () => import( /* webpackChunkName: "RentProductType" */ '../views/Rent/RentProductType.vue'),
        props: {
            productId: 1
        },
        meta: {
            sitemap: {
                priority: 0.8
            }
        }
    },
    {
        path: '/Rent/ChildrenSkis',
        name: 'ChildrenSkis',
        component: () => import( /* webpackChunkName: "RentProductType" */ '../views/Rent/RentProductType.vue'),
        props: {
            productId: 2
        },
        meta: {
            sitemap: {
                priority: 0.8
            }
        }
    },
    {
        path: '/Rent/ChildrenSnowboards',
        name: 'ChildrenSnowboards',
        component: () => import( /* webpackChunkName: "RentProductType" */ '../views/Rent/RentProductType.vue'),
        props: {
            productId: 6
        },
        meta: {
            sitemap: {
                priority: 0.8
            }
        }
    },
    {
        path: '/Rent/Snowboards',
        name: 'Snowboards',
        component: () => import( /* webpackChunkName: "RentProductType" */ '../views/Rent/RentProductType.vue'),
        props: {
            productId: 3
        },
        meta: {
            sitemap: {
                priority: 0.8
            }
        }
    },
    {
        path: '/Rent/Monoskis',
        name: 'Monoskis',
        component: () => import( /* webpackChunkName: "RentProductType" */ '../views/Rent/RentProductType.vue'),
        props: {
            productId: 4
        },
        meta: {
            sitemap: {
                priority: 0.8
            }
        }
    },
    {
        path: '/Rent/Boots',
        name: 'Boots',
        component: () => import( /* webpackChunkName: "RentProductType" */ '../views/Rent/RentProductType.vue'),
        props: {
            productId: 5
        },
        meta: {
            sitemap: {
                priority: 0.8
            }
        }
    }
];