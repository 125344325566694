






































import { Order, OrderProduct } from '@/models/model';
import { Component, Vue } from 'vue-property-decorator';
import order from '@/store/order';
import CartProducts from './CartProducts.vue';
import priceCalculator from '@/helper/priceCalculator';

@Component({ components: { CartProducts } })
export default class CartPanel extends Vue {

    goTo(path: string): void {
        this.$router.push(path);
    }

    getPrice(orderProduct: OrderProduct): number {
        return priceCalculator.getPrice(this.currentOrder!, orderProduct);
    }

    get totalPrice(): number {
        return priceCalculator.getTotalPrice(this.currentOrder!);
    }

    get totalPriceBeforePromotions(): number {
        return priceCalculator.getTotalPriceBeforePromotions(this.currentOrder!);
    }

    get deposit(): number {
        return priceCalculator.getDeposit(this.currentOrder!);
    }

    get currentOrder(): Order | undefined {
        return order.currentOrder;
    }

}
