
























































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import moment from "moment";
import { ClientTable } from "vue-tables-2";
import user from "@/store/user";
import order from "@/store/order";
import Order, { Language, User } from "./models/model";
import Footer from "@/components/Footer.vue";
import datePicker from "vue-bootstrap-datetimepicker";
import EasySlider from 'vue-easy-slider';
import CartPanel from '@/components/Cart/CartPanel.vue';
import home from "./store/home";
import languages from '@/helper/languages';
import Toaster from '@/components/Utils/Toaster.vue';
import { Route } from "vue-router";

Vue.use(datePicker);
Vue.use((EasySlider as any));
Vue.use(ClientTable, { options: {}, useVuex: false, theme: 'bootstrap3', template: 'default' });

Vue.filter('fullDate', function(value: string): string | undefined {
  if (value) {
    return moment(value).format('DD/MM/YYYY hh:mm:ss A');
  }
});

Vue.filter('shortDate', function(value: string): string | undefined {
  if (value) {
    return moment(value).format('DD/MM/YYYY');
  }
});

Vue.filter('shortWrittenDate', function(value: string): string | undefined {
  if (value) {
    return moment(value).format('DD MMM YYYY');
  }
});

Vue.filter('longWrittenDate', function(value: string): string | undefined {
  if (value) {
    const str = moment(value).format('ddd DD MMM YYYY');
    return str.length > 1 ? str.substr(0, 1).toUpperCase() + str.substr(1) : str;
  }
});

@Component({ components: { Footer, CartPanel, Toaster }, metaInfo() {
  return {
    title: 'Location de skis',
    titleTemplate: `%s | Caron Ski Shop - ${this.$t('meta.skiRental')}`,
    meta: [
      {
        name: 'robots',
        content: 'index, follow'
      },
      {
        name: 'og:type',
        content: 'website'
      },
      {
        name: 'og:url',
        content: 'https://www.caronskishop.com'
      },
      {
        name: 'twitter:url',
        content: 'https://www.caronskishop.com'
      },
      {
        name: 'twitter:card',
        content: 'summary_large_image'
      },
      {
        name: 'og:image',
        content: 'https://www.caronskishop.com/preview.jpg'
      },
      {
        name: 'twitter:image',
        content: 'https://www.caronskishop.com/preview.jpg'
      }
    ]
  }
}})
export default class App extends Vue {

  private loaded = false;

  @Watch('route')
  routeChange(): void {
    this.checkActivation();
  }

  mounted(): void {
    moment.locale(this.selectedLanguage);
    order.dispatchLoadCurrentOrder();
    if (!home.products.length) {
      home.dispatchLoadDatas().then(() => this.checkAuth());
    } else {
      this.checkAuth();
    }
  }

  checkAuth(): void {
    user.dispatchCheckAuth().then(() => {
      this.loaded = true;
      this.checkActivation();
      setTimeout(() => {
        new (window as any).HSHeader('#header').init();
        new (window as any).HSMegaMenu('.js-mega-menu', {
            desktop: {
              position: 'left'
            }
          });
        // (window as any).HSBsDropdown.init();
      }, 200);
    });
  }

  checkActivation(): void {
    if (this.$route.name != 'Activation' && this.currentUser && !this.currentUser.emailConfirmed) {
      this.$router.push('/Login');
    }
  }

  selectLang(lang: string): void {
      this.$root.$i18n.locale = lang;
      moment.locale(lang);
  }

  getSelectedLanguageKey(): string | undefined {
      return this.languages.find(l => l.id === this.selectedLanguage)?.nameKey;
  }

  getImgUrl(key: string): void {
      return require(`./assets/${key}.svg`);
  }

  goTo(path: string): void {
    this.$router.push(path);
  }

  signout(): void {
    user.dispatchSignout().then(() => this.$router.push('/'));
  }

  get languages(): Language[] {
      return languages.getLanguages();
  }

  get selectedLanguage(): string {
      return this.$root.$i18n.locale;
  }

  get loggedIn(): boolean {
    return user.loggedIn;
  }

  get currentUser(): User | undefined {
    return user.currentUser;
  }

  get admin(): boolean {
    return !!user.currentUser && !!user.currentUser.roles && user.currentUser.roles.indexOf("Admin") !== -1;
  }

  get manager(): boolean {
    return !!user.currentUser && !!user.currentUser.roles && (user.currentUser.roles.indexOf("Manager") !== -1 || this.admin);
  }

  get order(): Order | undefined {
    return order.currentOrder;
  }

  get route(): Route {
    return this.$route;
  }

  get env(): string {
    return process.env.VUE_APP_SERV_ENV;
  }
}

