




























import { Component, Vue } from 'vue-property-decorator';
import EditTable from '@/components/Admin/EditTable.vue';
import { Banner, TableColumnDefinition } from '@/models/model';
import admin from '@/store/admin';
import user from '@/store/user';

@Component({
  components: {
    EditTable
  },
})
export default class Banners extends Vue {

    private columns: TableColumnDefinition[] = [
        { propertyName: 'label', label: 'Name' },
        { propertyName: 'labelEn', label: 'Name (en)' },
        { propertyName: 'startDate', label: 'Start date' },
        { propertyName: 'endDate', label: 'End date' },
        { propertyName: 'promotion', label: 'Promotion' },
        { propertyName: 'promotionPct', label: 'Promotion (%)' }
    ];

    mounted(): void {
      if (!this.admin) {
        this.$router.push('/');
      }

      admin.dispatchLoadBanners();
    }

    addBanners(): void {
        admin.editedBanner = ({label: '', labelEn: '', startDate: '', endDate: '', promotion: false, promotionPct: 0 } as any);
        this.$router.push('/Admin/Banners/Edit');
    }

    get banners(): Banner[] {
        return admin.banners.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0);
    }

    get admin(): boolean {
      return !!user.currentUser && !!user.currentUser.roles && user.currentUser.roles.indexOf("Admin") !== -1;
    }
}
