import { MoneticoParams, Order, OrderProduct, PromotionCode } from "../models/model";
import { OrderState, RootState } from "../models/state";
import { BareActionContext, getStoreBuilder } from "vuex-typex";
import Api, { convertJsonDates } from "@/api/api";
import toaster, { ToastImpl } from "./toaster";

const initialOrderState: OrderState = {
    orders: [],
    currentOrder: undefined,
    loading: false
};
const b = getStoreBuilder<RootState>().module("order", initialOrderState);

const loadingGetter = b.read((state: OrderState) => state.loading, "loading");
const ordersGetter = b.read((state: OrderState) => state.orders, "orders");
const currentOrderGetter = b.read((state: OrderState) => state.currentOrder, "currentOrder");

function removeOrderInLocalStorage(): void {
    localStorage.removeItem('order');
}

function saveOrderInLocalStorage(state: OrderState): void {
    localStorage.setItem('order', JSON.stringify(state.currentOrder));
}

function getOrderInLocalStorage(): void {
    const orderStr = localStorage.getItem('order');
    if (orderStr) {
        try {
            const json: Order = JSON.parse(orderStr);
            convertJsonDates(json, ['startDate', 'endDate', 'createdOn']);

            if (json.createdOn) {
                const diffTime = Math.abs((new Date()).getTime() - json.createdOn.getTime());
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                if (diffDays > 2) {
                    localStorage.removeItem('order');
                    return;
                }
            }
            order.commitSetCurrentOrder(json);
        } catch (error) {
            localStorage.removeItem('order');
        }
    }
}

function setLoading(state: OrderState, payload: boolean): void
{
    state.loading = payload;
}

function setOrders(state: OrderState, payload: Order[]): void
{
    state.orders = payload;
}

function setCurrentOrder(state: OrderState, payload: Order | undefined): void
{
    if (payload) {
        payload!.createdOn = new Date();
    }
    state.currentOrder = payload;
    saveOrderInLocalStorage(state);
}

function setAddProduct(state: OrderState, payload: OrderProduct): void
{
    if (state.currentOrder) {
        state.currentOrder.orderProducts.push(payload);
    } else {
        state.currentOrder = ({
            number: 1,
            orderProducts: [payload],
            promotionCodeId: 0,
            promotionCode: '',
            globalPromotionId: 0,
            globalPromotion: ''
        } as any);
    }
    saveOrderInLocalStorage(state);
}

function setRemoveProduct(state: OrderState, payload: OrderProduct): void
{
    if (state.currentOrder) {
        state.currentOrder.orderProducts.splice(state.currentOrder.orderProducts.indexOf(payload), 1);
        if (!state.currentOrder.orderProducts.length) {
            state.currentOrder.promotionCode = ('' as any);
            state.currentOrder.promotionCodeId = '';
            state.currentOrder.globalPromotion = ('' as any);
            state.currentOrder.globalPromotionId = '';
        }
        saveOrderInLocalStorage(state);
    }
}

async function loadOrders(): Promise<boolean>
{
    order.loading = true;
    const loadedOrders = await Api.getOrders();
    order.loading = false;

    convertJsonDates(loadedOrders, ['startDate', 'endDate', 'createdOn', 'endedOn']);

    order.orders = loadedOrders;

    return true;
}

async function addOrder(context: BareActionContext<OrderState, RootState>, toSaveOrder: {order: Order, lang: string}): Promise<MoneticoParams>
{
    // Fix dates
    toSaveOrder.order.orderProducts.forEach(p => {
        p.startDateStr = p.startDate.toLocaleString('fr-FR').substr(0, 10);
        p.endDateStr = p.endDate.toLocaleString('fr-FR').substr(0, 10);
    });

    const savedOrder = await Api.addOrder(toSaveOrder.order, toSaveOrder.lang);

    if (savedOrder.error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', savedOrder.error));
        console.log('Error: ' + savedOrder.error);
        return (undefined as any);
    }

    removeOrderInLocalStorage();
    order.commitSetCurrentOrder(undefined);

    return savedOrder;
}

function loadCurrentOrder(): Promise<boolean>
{
    getOrderInLocalStorage();

    return new Promise((resolve) => {
        resolve(true);
    });
}

async function cancelOrder(context: BareActionContext<OrderState, RootState>, orderId: string): Promise<boolean> {
    const canceledOrder = await Api.cancelOrder(orderId);

    if (canceledOrder.error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', canceledOrder.error));
        console.log('Error: ' + canceledOrder.error);
        return false;
    }

    return true;
}

async function checkCode(context:BareActionContext<OrderState, RootState>, code: string): Promise<PromotionCode> {
    try {
        const promotionCode = await Api.checkCode(code);
        if (promotionCode.error) {
            return (undefined as any);
        }
        convertJsonDates(promotionCode, ['startDate', 'endDate']);
        return promotionCode;
    } catch (error) {
        return (undefined as any);
    }
}

const stateGetter = b.state()

const order = {
    get state(): OrderState { return stateGetter() },

    get loading(): boolean { return loadingGetter() },
    set loading(value: boolean) { this.commitSetLoading(value) },

    get orders(): Order[] { return ordersGetter() },
    set orders(value: Order[]) { this.commitSetOrders(value); },

    get currentOrder(): Order | undefined { return currentOrderGetter() },
    set currentOrder(value: Order | undefined) { this.commitSetCurrentOrder(value); },

    commitSetLoading: b.commit(setLoading),
    commitSetOrders: b.commit(setOrders),
    commitSetCurrentOrder: b.commit(setCurrentOrder),
    commitSetAddProduct: b.commit(setAddProduct),
    commitSetRemoveProduct: b.commit(setRemoveProduct),

    dispatchLoadOrders: (b.dispatch(loadOrders) as (param?: any) => Promise<boolean>),
    dispatchAddOrder: (b.dispatch(addOrder) as (param?: any) => Promise<MoneticoParams>),
    dispatchLoadCurrentOrder: (b.dispatch(loadCurrentOrder) as (param?: any) => Promise<boolean>),
    dispatchCancelOrder: (b.dispatch(cancelOrder) as (param?: any) => Promise<boolean>),
    dispatchCheckCode: (b.dispatch(checkCode) as (param?: any) => Promise<PromotionCode>)
}

export default order