











































































import { Component, Vue, Watch } from 'vue-property-decorator';
import EditTable from '@/components/Admin/EditTable.vue';
import Order, { DepositState, Season } from '@/models/model';
import admin from '@/store/admin';
import user from '@/store/user';
import { Line } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)


interface KpiDataItem {
  number: number;
  pourcentage: number;
  lastYear: number;
  label: string;
  isMoney: boolean;
}

@Component({
  components: {
    EditTable,
    'LineChart': Line
  }
})
export default class Kpi extends Vue {

    public seasons: Season[] = [];
    public selectedSeason: Season = ({} as any);
    public topKpis: KpiDataItem[] = [];

    public chartData = {
      labels: [] as string[],
      datasets: [
        {
          backgroundColor: ['rgba(55,125,255, .5)', 'rgba(255, 255, 255, .2)'],
          borderColor: '#377dff',
          borderWidth: 2,
          pointRadius: 0,
          hoverBorderColor: '#377dff',
          pointBackgroundColor: '#377dff',
          pointBorderColor: '#fff',
          pointHoverRadius: 0,
          tension: 0.4,
          data: [] as number[],
          label: ''
        },
        {
          backgroundColor: ['rgba(0, 201, 219, .5)', 'rgba(255, 255, 255, .2)'],
          borderColor: '#00c9db',
          borderWidth: 2,
          pointRadius: 0,
          hoverBorderColor: '#00c9db',
          pointBackgroundColor: '#00c9db',
          pointBorderColor: '#fff',
          pointHoverRadius: 0,
          tension: 0.4,
          data: [] as number[],
          label: ''
        }
      ]
    };
    public chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          grid: {
            color: '#e7eaf3',
            drawBorder: false,
            zeroLineColor: '#e7eaf3'
          },
          ticks: {
            min: 0,
            max: 100,
            stepSize: 1000,
            fontColor: '#97a4af',
            fontFamily: 'Open Sans, sans-serif',
            padding: 10,
            postfix: 'k'
          }
        },
        x: {
          grid: {
            display: false,
            drawBorder: false
          },
          ticks: {
            fontSize: 12,
            fontColor: '#97a4af',
            fontFamily: 'Open Sans, sans-serif',
            padding: 5
          }
        }
      },
      plugins: {
        tooltip: {
          enabled: true,
          mode: 'index',
          intersect: false,
          position: 'nearest',
          bodySpacing: 8,
          usePointStyle: true,
          callbacks: {
            label: (context: { dataset: { label: string, data: number[] }, dataIndex: number }): string => {
              const label = context.dataset.label;
              const value = context.dataset.data[context.dataIndex];
              return ` ${label}: ${value.toLocaleString()}`;
            }
          }
        },
        legend: {
          display: true,
          fullSize: true,
          labels: {
            usePointStyle: true,
            fontColor: '#97a4af',
            fontFamily: 'Open Sans, sans-serif'
          }
        },
      },
    };

    public revenueChartData = { labels: [] as string[], datasets: [] as any[] };
    public revenueChartOptions: any = {};

    public ordersChartData = { labels: [] as string[], datasets: [] as any[] };
    public ordersChartOptions: any = {};

    @Watch('selectedSeason')
    onSelectedSeasonChanged(): void {
      this.init();
    }

    mounted(): void {
        if (!this.manager) {
          this.$router.push('/');
        }

        this.initSeasons();

        admin.dispatchLoadOrders().then(() => this.init());
    }

    initSeasons(): void {
      const startYear = 2021;
      const date = new Date();
      const currentYear = date.getFullYear() - (date.getMonth() <= 6 ? 1 : 0);
      const years = [...Array(currentYear - startYear).keys()];
      for (let i = years.length; i >= 0; i--) {
        const currentSeason = startYear + i;
        this.seasons.push({
          id: i.toString(),
          name: currentSeason + '/' + (currentSeason + 1),
          startDate: new Date(currentSeason, 8, 1),
          endDate: new Date(currentSeason + 1, 7, 30),
          active: true
        });
      }

      this.selectedSeason = this.seasons[0];
    }

    init(): void {
      // Kpis
      this.topKpis = [];
      this.topKpis.push(this.generateKpiDataItem(o => o.price, 'view.kpi.revenue', true));
      this.topKpis.push(this.generateKpiDataItem(o => o.price / o.orderProducts.length, 'view.kpi.avgBasket', true, true));
      this.topKpis.push(this.generateKpiDataItem(() => 1, 'view.kpi.ordersNb', false));

      // Charts
      if (this.orders.length === 0) {
        this.revenueChartData = {...this.chartData, datasets: [...this.chartData.datasets.map(d => { return {...d, data: [] as number[]}; })], labels: [...this.chartData.labels]};
        this.ordersChartData = {...this.chartData, datasets: [...this.chartData.datasets.map(d => { return {...d, data: [] as number[]}; })], labels: [...this.chartData.labels]};
        return;
      }
      const revenueChartData = {...this.chartData, datasets: [...this.chartData.datasets.map(d => { return {...d, data: [] as number[]}; })], labels: [...this.chartData.labels]};
      const ordersByMonth = this.orders.reduce((acc, order) => {
        const month = new Date(order.orderProducts[0].startDate.getFullYear(),
          order.orderProducts[0].startDate.getMonth(),
          1);
        const monthKey = month.getTime().toString();
        if (!acc[monthKey]) {
          acc[monthKey] = [];
        }
        acc[monthKey].push(order);
        return acc;
      }, {} as { [month: string]: Order[] });
      const ordererKeys = Object.keys(ordersByMonth).sort();

      const ordersChartData = {...this.chartData, datasets: [...this.chartData.datasets.map(d => { return {...d, data: [] as number[]}; })], labels: [...this.chartData.labels]};

      ordersChartData!.datasets![0].data = ordererKeys.map(month => ordersByMonth[month].length);
      ordersChartData!.datasets![0].label = this.selectedSeason.name;
      ordersChartData!.labels = ordererKeys.map(month => {
        const date = new Date(parseInt(month));
        const dateString = date.toLocaleString('default', { month: 'long', year: 'numeric' });
        return dateString.charAt(0).toUpperCase() + dateString.slice(1);
      });

      revenueChartData!.datasets![0].data = ordererKeys.map(month => ordersByMonth[month].reduce((acc, order) => acc + order.price, 0));
      revenueChartData!.datasets![0].label = this.selectedSeason.name;
      revenueChartData!.labels = ordererKeys.map(month => {
        const date = new Date(parseInt(month));
        const dateString = date.toLocaleString('default', { month: 'long', year: 'numeric' });
        return dateString.charAt(0).toUpperCase() + dateString.slice(1);
      });

      if (this.lastSeason) {
        const lastYearOrdersByMonth = this.lastYearOrders.reduce((acc, order) => {
          const month = new Date(order.orderProducts[0].startDate.getFullYear() + 1,
            order.orderProducts[0].startDate.getMonth(),
            1);
          const monthKey = month.getTime().toString();
          if (!acc[monthKey]) {
            acc[monthKey] = [];
          }
          acc[monthKey].push(order);
          return acc;
        }, {} as { [month: string]: Order[] });
        revenueChartData!.datasets![1].data = ordererKeys.map(month => {
          if (lastYearOrdersByMonth[month]) {
            return lastYearOrdersByMonth[month].reduce((acc, order) => acc + order.price, 0);
          }
          return 0;
        });
        ordersChartData!.datasets![1].data = ordererKeys.map(month => {
          if (lastYearOrdersByMonth[month]) {
            return lastYearOrdersByMonth[month].length;
          }
          return 0;
        });
        revenueChartData!.datasets![1].label = this.lastSeason.name;
        ordersChartData!.datasets![1].label = this.lastSeason.name;
      } else {
        revenueChartData!.datasets![1].data = ordererKeys.map(() => 0);
        ordersChartData!.datasets![1].data = ordererKeys.map(() => 0);
      }
      this.revenueChartData = revenueChartData;
      this.revenueChartOptions = {
        ...this.chartOptions,
        scales: {
          ...this.chartOptions.scales,
          y: {
            ...this.chartOptions.scales.y,
            ticks: {
              ...this.chartOptions.scales.y.ticks,
              callback: (value: number) => value.toLocaleString() + ' €'
            }
          }
        },
        plugins:
        {
          ...this.chartOptions.plugins,
          tooltip: {
            ...this.chartOptions.plugins.tooltip,
            callbacks: {
              ...this.chartOptions.plugins.tooltip.callbacks,
              label: (context: any) => {
                const label = context.dataset.label;
                const value = context.dataset.data[context.dataIndex];
                return ` ${label}: ${value.toLocaleString()} €`;
              }
            }
          }
        }
      };
      this.ordersChartData = ordersChartData;
      this.ordersChartOptions = {
        ...this.chartOptions,
        scales: {
          ...this.chartOptions.scales,
          y: {
            ...this.chartOptions.scales.y,
            ticks: {
              ...this.chartOptions.scales.y.ticks,
              stepSize: 10,
            }
          }
        },
      };
    }

    generateKpiDataItem(getData: (order: Order) => number, label: string, isMoney: boolean, avg = false): KpiDataItem {
      const aggFunc = avg ?
        (items: Order[]) => items.reduce((a, b) => a + getData(b), 0) / items.length :
        (items: Order[]) => items.reduce((a, b) => a + getData(b), 0);

      const dataItem: KpiDataItem = {
          number: this.orders.length ? Math.round(aggFunc(this.orders) * 100) / 100 : 0,
          pourcentage: 100,
          lastYear: 0,
          label,
          isMoney
        };

      if (this.lastSeason) {
        if (this.lastYearOrders.length) {
          dataItem.lastYear = Math.round(aggFunc(this.lastYearOrders) * 100) / 100;
          dataItem.pourcentage = Math.round((dataItem.number / dataItem.lastYear - 1) * 10000) / 100;
        }
      }

      return dataItem;
    }

    get manager(): boolean {
      return !!user.currentUser && !!user.currentUser.roles
        && (user.currentUser.roles.indexOf("Manager") !== -1 || user.currentUser.roles.indexOf("Admin") !== -1);
    }

    get lastSeason(): Season | undefined {
      return this.seasons.find(s => s.id === (parseInt(this.selectedSeason.id) - 1).toString())!;
    }

    get orders(): Order[] {
      return admin.orders.filter(o =>
        !o.endedOn && (o.depositState == DepositState.None || o.depositState == DepositState.Successfull) &&
        (o.orderProducts && o.orderProducts.length ?
        o.orderProducts[0].startDate >= this.selectedSeason.startDate && o.orderProducts[0].endDate <= this.selectedSeason.endDate :
        o.createdOn >= this.selectedSeason.startDate && o.createdOn <= this.selectedSeason.endDate));
    }

    get lastYearOrders(): Order[] {
      if (!this.lastSeason) {
        return [];
      }
      return admin.orders.filter(o =>
          !o.endedOn && (o.depositState == DepositState.None || o.depositState == DepositState.Successfull) &&
          (o.orderProducts && o.orderProducts.length ?
          o.orderProducts[0].startDate >= this.lastSeason!.startDate && o.orderProducts[0].endDate <= this.lastSeason!.endDate :
          o.createdOn >= this.lastSeason!.startDate && o.createdOn <= this.lastSeason!.endDate));
    }
}
