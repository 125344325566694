




























import { Component, Vue } from 'vue-property-decorator';
import EditTable from '@/components/Admin/EditTable.vue';
import { PromotionCode, TableColumnDefinition } from '@/models/model';
import admin from '@/store/admin';
import user from '@/store/user';

@Component({
  components: {
    EditTable
  },
})
export default class Codes extends Vue {

    private columns: TableColumnDefinition[] = [
        { propertyName: 'code', label: 'Code' },
        { propertyName: 'label', label: 'Name' },
        { propertyName: 'labelEn', label: 'Name (en)' },
        { propertyName: 'dates', label: 'Dates', type: 'DateRange' },
        { propertyName: 'promotion', label: 'Promotion (%)' },
        { propertyName: 'minRentDays', label: 'Min rent days' }
    ];

    mounted(): void {
      if (!this.admin) {
        this.$router.push('/');
      }

      admin.dispatchLoadCodes();
    }

    addCodes(): void {
        admin.editedCode = ({label: '', labelEn: '', dates: [{startDate: '', endDate: ''}], promotion: 0, code: '', minRentDays: 0 } as any);
        this.$router.push('/Admin/Codes/Edit');
    }

    get codes(): PromotionCode[] {
        return admin.codes.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0);
    }

    get admin(): boolean {
      return !!user.currentUser && !!user.currentUser.roles && user.currentUser.roles.indexOf("Admin") !== -1;
    }
}
